import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { isCompleted } from '@mychrono/models';

@Pipe({
  name: 'isCompleted',
})
export class IsCompletedPipe implements PipeTransform {
  transform(obj: { completedAt?: string } | any): boolean {
    if (!obj.completedAt) {
      return false;
    }
    return isCompleted({ completedAt: obj.completedAt });
  }
}

@NgModule({
  declarations: [IsCompletedPipe],
  exports: [IsCompletedPipe],
})
export class IsCompletedPipeModule {}
