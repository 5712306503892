import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNowStrict, isBefore, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

@Pipe({
  name: 'dateFromNow',
})
export class DateFromNowPipe implements PipeTransform {
  transform(obj: Date | string | undefined): string {
    if (!obj) {
      return '';
    }
    const d = obj instanceof Date ? obj : parseISO(obj);
    return `${
      isBefore(d, Date.now()) ? `il y a ` : 'dans '
    }${formatDistanceToNowStrict(d, { locale: fr })}`;
  }
}

@NgModule({
  declarations: [DateFromNowPipe],
  exports: [DateFromNowPipe],
})
export class DateFromNowPipeModule {}
