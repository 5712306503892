import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { isStarted } from '@mychrono/models';

@Pipe({
  name: 'isStarted',
})
export class IsStartedPipe implements PipeTransform {
  transform(obj: { startedAt?: string } | any): boolean {
    if (!obj.startedAt) {
      return false;
    }
    return isStarted({ startedAt: obj.startedAt });
  }
}

@NgModule({
  declarations: [IsStartedPipe],
  exports: [IsStartedPipe],
})
export class IsStartedPipeModule {}
