import { UserFindParams } from './user.interface';
import { DossierFindParams } from './dossier.interface';
import { TemplateFindParams } from './template.interface';
import { ProjectFindParams } from './project.interface';

export interface Resource {
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export enum PrivacyEnum {
  Private = 'private', // user only
  Admins = 'admins', // all admins
  Organization = 'organization', // all collaborators
  Public = 'public', // all users
}

export enum MethodEnum {
  Find = 'find',
  Get = 'get',
  Create = 'create',
  Update = 'update',
  Patch = 'patch',
  Remove = 'remove',
}

export enum ServiceEnum {
  RefreshToken = 'refresh-token',
  Authentication = 'authentication',
  AuthManagement = 'authentication/management',
  Mailer = 'mailer',
  Users = 'users',
  Activity = 'activity',
  Dossiers = 'dossiers',
  Projects = 'projects',
  Tasks = 'tasks',
  TaskComments = 'task-comments',
  TaskAttachments = 'task-attachments',
  FileStorage = 'file-storage',
  Contacts = 'contacts',
  Templates = 'templates',
  Notifications = 'notifications',
  DossierTypes = 'dossier-types',
  DossierFormats = 'dossier-formats',
  SkillDomains = 'skill-domains',
  SkillProfiles = 'skill-profiles',
  ClosingMotives = 'closing-motives',
  DossierComments = 'dossier-comments',
  ProjectComments = 'project-comments',
  Resources = 'resources',
  Offices = 'offices',
  Stats1 = 'stats/profiles',
  Stats2 = 'stats/repartition',
  Stats3 = 'stats/pilotage',
  Stats4 = 'stats/global',
}

export const ResourceRelatedServices: ServiceEnum[] = [
  ServiceEnum.Users,
  ServiceEnum.Dossiers,
  ServiceEnum.Projects,
  ServiceEnum.Templates,
];

export type GlobalFindParams = UserFindParams &
  DossierFindParams &
  TemplateFindParams &
  ProjectFindParams;
