import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FeaturesModule } from '@features/features.module';
import { CoreComponent } from '@core/components/core/core.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AuthenticationState } from '@stores/authentication/authentication.state';
import { HttpAuthenticationInterceptor } from '@core/interceptors/authentication.interceptor';
import { environment } from '@environment';
import { ServerErrorsInterceptor } from '@core/interceptors/server-errors.interceptor';
import { NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastsModule } from '@mychrono/ui-components';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { i18nDatepickerService } from '@core/services/i18n-datepicker.service';
import { IconsModule } from '@domains/icons/icons.module';
import { UserState } from '@stores/user/user.state';
import {
  DateFnsConfigurationService,
  DateFnsModule,
  ParsePipeModule,
} from 'ngx-date-fns';
import { fr } from 'date-fns/locale';
import { ContentState } from '@stores/content/content.state';
import { ResourcesState } from '@stores/resources/resources.state';

registerLocaleData(localeFr);
const frenchConfig = new DateFnsConfigurationService();
frenchConfig.setLocale(fr);

@NgModule({
  declarations: [CoreComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([], {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
    NgxsModule.forRoot(
      [AuthenticationState, UserState, ContentState, ResourcesState],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsStoragePluginModule.forRoot({
      key: [AuthenticationState],
    }),
    FeaturesModule,
    NgbModule,
    IconsModule,
    ToastsModule,
    DateFnsModule.forRoot(),
    ParsePipeModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'fr',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: NgbDatepickerI18n,
      useClass: i18nDatepickerService,
    },
    { provide: DateFnsConfigurationService, useValue: frenchConfig },
  ],
  bootstrap: [CoreComponent],
})
export class CoreModule {}
