import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface Toast {
  header?: string;
  content: string | TemplateRef<any>;
  icon?: IconProp;
  class?: 'success' | 'danger' | 'primary' | 'warning';
  options?: {
    delay?: number;
  };
}

@Injectable()
export class ToastService {
  private toasts$ = new BehaviorSubject<Toast[]>([]);

  show(toast: Toast) {
    const toasts = [...this.toasts$.value];
    toasts.push(toast);
    this.toasts$.next(toasts);
  }

  get toastsAsObservable(): Observable<Toast[]> {
    return this.toasts$.asObservable();
  }

  remove(toast: Toast) {
    this.toasts$.next(
      this.toasts$.value.filter((t) => t.content !== toast.content)
    );
  }

  clear() {
    this.toasts$.value.splice(0, this.toasts$.value.length);
  }
}
