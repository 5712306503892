import { RecursivePartial, RoleEnum, User } from './user.interface';
import { Resource } from './common.interface';
import { hasMinimalRole } from './util';
import { Dossier } from './dossier.interface';

export interface Project extends Resource {
  name: string;
  description: string;
  creatorId: string;
  startDate?: string;
  deadlineDate?: string;
  attendeeIds: string[];
  managerIds: string[];
  clientIds: string[];
  clientNames: string[];
  officeId?: string;
}

export type ExtendedProject = Project & {
  dossierCount: number;
};

export type FullProject = Project & {
  attendees?: User[];
  dossiers?: Dossier[];
};

export type ProjectUpdate = Partial<RecursivePartial<Project>> &
  Pick<Resource, '_id'>;

export type ProjectCreate = Omit<RecursivePartial<Project>, keyof Resource>;

export const PROJECT_SEARCHABLE_FIELDS: string[] = [
  'name',
  'description',
  'clientNames',
];

export interface ProjectFindParams {
  searchValue?: string;
  limit?: number;
  skip?: number;
  total?: number;
  sortField?: keyof Project | string;
  sortOrder?: 1 | -1;
  projectAttendeeLevel?: 'attendee' | 'creator' | 'none';
  attendeeId?: string;
  officeId?: string;
}

export function canEditProject(project: Project, user: User): boolean {
  if (hasMinimalRole(RoleEnum.Admin, user)) {
    return true;
  }

  if (
    project.creatorId.toString() === user._id.toString() ||
    project.managerIds.map((i) => i.toString()).includes(user._id.toString())
  ) {
    return true;
  }
  return false;
}

export function canViewProject(user: User, project?: Project): boolean {
  if (!project) {
    return false;
  }
  if (!user.officeId) {
    return true;
  }
  if (canEditProject(project, user)) {
    return true;
  }
  if (user.officeId === project.officeId) {
    return true;
  }
  return false;
}
