import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bigbytes',
})
export class BigBytesPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    const suffixes = ['Ko', 'Mo', 'Go', 'To'];

    if (Number.isNaN(input)) {
      return null;
    }

    if (input < 1000) {
      return input + ' o';
    }

    const exp = Math.floor(Math.log(input) / Math.log(1000));

    return (
      (input / Math.pow(1000, exp)).toFixed(args) + ' ' + suffixes[exp - 1]
    );
  }
}

@NgModule({
  declarations: [BigBytesPipe],
  exports: [BigBytesPipe],
})
export class BigBytesPipeModule {}
