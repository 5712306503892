<div class="box">
  <div class="box__icon" *ngIf="hasIcon">
    <ng-content select="[icon]"></ng-content>
  </div>

  <h1 class="box__title" *ngIf="title">{{ title }}</h1>

  <h2 class="box__subtitle" *ngIf="subtitle">
    {{ subtitle }}
  </h2>

  <div class="box__content">
    <ng-content select="[content]"> </ng-content>
  </div>
</div>
