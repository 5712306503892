import { isBefore, parseISO } from 'date-fns';
import { PersonTypeEnum, RoleEnum, User } from './user.interface';

export function isArchived(obj: { archivedAt?: Date | string }): boolean {
  return !!obj.archivedAt && isPassed(obj, 'archivedAt');
}

export function isCompleted(obj: { completedAt?: Date | string }): boolean {
  return !!obj.completedAt && isPassed(obj, 'completedAt');
}

export function isDeadlinePassed(obj: {
  deadlineDate?: Date | string;
}): boolean {
  return !!obj.deadlineDate && isPassed(obj, 'deadlineDate');
}
export function isOpeningPassed(obj: { openingDate?: Date | string }): boolean {
  return !!obj.openingDate && isPassed(obj, 'openingDate');
}

export function isDueDatePassed(obj: { dueAt?: Date | string }): boolean {
  return !!obj.dueAt && isPassed(obj, 'dueAt');
}

export function isStarted(obj: {
  startedAt?: Date | string;
  openingDate?: Date | string;
}): boolean {
  if (obj.openingDate) {
    return isPassed(obj, 'openingDate');
  }
  return !!obj.startedAt && isPassed(obj, 'startedAt');
}

export function isPublished(obj: { publishedAt?: Date | string }): boolean {
  return !!obj.publishedAt && isPassed(obj, 'publishedAt');
}

export function isPassed(obj: any, key: string): boolean {
  return (
    !!obj[key] &&
    isBefore(
      obj[key] instanceof Date ? obj[key] : parseISO(obj[key]),
      new Date()
    )
  );
}

// eslint-disable-next-line no-irregular-whitespace
export const SMALL_SPACE_CHAR = ` `;

/**
 * Returns true if role is sufficient
 * @param role: role to compare
 * @param user: user databag to check access
 */
export function hasMinimalRole(
  role: RoleEnum,
  user: Pick<User, 'role'>
): boolean {
  switch (role) {
    case RoleEnum.SuperAdmin:
      return [RoleEnum.SuperAdmin].includes(user.role);
    case RoleEnum.Admin:
      return [RoleEnum.SuperAdmin, RoleEnum.Admin].includes(user.role);
    case RoleEnum.Collaborator:
      return [
        RoleEnum.SuperAdmin,
        RoleEnum.Admin,
        RoleEnum.Collaborator,
      ].includes(user.role);
    case RoleEnum.Visitor:
      return [
        RoleEnum.SuperAdmin,
        RoleEnum.Admin,
        RoleEnum.Collaborator,
        RoleEnum.Visitor,
      ].includes(user.role);
    default:
      console.error(`Unknown role '${role}'`);
      return false;
  }
}

/**
 * Get user type label depending on his role
 */
export function getUserTypeLabel(user?: User, admin = true): string {
  switch (user?.role) {
    case RoleEnum.SuperAdmin:
      return admin ? 'Super-administrateur' : 'Collaborateur';
    case RoleEnum.Admin:
      return admin ? 'Administrateur' : 'Collaborateur';
    case RoleEnum.Collaborator:
      return 'Collaborateur';
    case RoleEnum.Visitor:
      return user.isClient === true ? 'Client' : 'Externe';
    default:
      throw new Error(`Unknown role ${user?.role}`);
  }
}

/**
 * Get username depending on his profile
 */
export function getUserName(
  user: User | null,
  options?: {
    useTitle?: boolean;
    lastnameFirst?: boolean;
    noFirstname?: boolean;
  }
): string {
  const capitalize = (val?: string): string => {
    if (!val) return '';
    return val.charAt(0).toUpperCase() + val.slice(1);
  };

  if (!user || !user.profile) {
    return `Utilisateur inconnu`;
  }
  if (user.profile.personType === PersonTypeEnum.Corporation) {
    return `${capitalize(user.profile.companyName)}`;
  }

  let titlePrefix = '';
  if (options?.useTitle && !!user.profile.title) {
    titlePrefix = `${capitalize(user.profile.title)} `;
  }

  if (options?.noFirstname || !user.profile.firstname) {
    return `${titlePrefix}${capitalize(user.profile.lastname)}`;
  }

  if (options?.lastnameFirst) {
    return `${titlePrefix}${capitalize(user.profile.lastname)} ${capitalize(
      user.profile.firstname
    )}`;
  }

  return `${titlePrefix}${capitalize(user.profile.firstname)} ${capitalize(
    user.profile.lastname
  )}`;
}

export const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const phoneRegex = new RegExp(
  /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/
);

export function isValidEmail(email?: string): boolean {
  if (!email) {
    return false;
  }
  return emailRegex.test(email.toLowerCase());
}
