import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nca-vip-chip',
  templateUrl: './vip-chip.component.html',
  styleUrls: ['./vip-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VipChipComponent {
  @Input() size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' = 'medium';
  @Input() tooltip: null | string = 'Dossier VIP';
}
