import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FeaturesRoutes } from '@features/features.routing';
import { IsLoggedGuard } from '@core/guards/is-logged.guard';
import { RoleService } from '@core/services/role.service';
import { LoggedLayoutModule } from '@commons/logged-layout/logged-layout.component';
import { IsMinAdminGuard } from '@core/guards/permissions/is-min-admin.guard';
import { IsMinCollaboratorGuard } from '@core/guards/permissions/is-min-collab.guard';
import { AllowFollowUpAccessGuard } from '@core/guards/permissions/allow-follow-up-access-guard.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(FeaturesRoutes),
    LoggedLayoutModule,
  ],
  providers: [
    IsLoggedGuard,
    RoleService,
    IsMinAdminGuard,
    IsMinCollaboratorGuard,
    AllowFollowUpAccessGuard,
  ],
})
export class FeaturesModule {}
