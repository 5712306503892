import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';

@Injectable()
export class i18nDatepickerService extends NgbDatepickerI18n {
  months = [
    '',
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  getMonthShortName(month: number): string {
    return this.months[month];
  }

  getMonthFullName(month: number): string {
    return this.months[month];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return format(new Date(date.year, date.month, date.day), 'dd-MM-yyyy');
  }

  getWeekdayLabel(weekday: number): string {
    return ['', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'][weekday];
  }
}
