<div class="wrapper">
  <a
    class="link"
    [routerLink]="item.clickable === false ? null : item.route"
    routerLinkActive="link--active"
    [class.link--active]="hasSubRoutes && dadRouteIsActive"
    [routerLinkActiveOptions]="{ exact: item.route?.toString() === '/' }"
    (click)="clickLink()"
  >
    <fa-icon
      class="btn-icon link__icon"
      *ngIf="item.icon"
      [icon]="item.icon"
    ></fa-icon>
    {{ item.label }}
  </a>

  <button
    class="wrapper__toggle"
    *ngIf="hasSubRoutes"
    (click)="toggleSubRoutes()"
  >
    <fa-icon
      class="wrapper__toggle__icon"
      [class.wrapper__toggle__icon--flip]="(showSubRoutes$ | async) === true"
      [icon]="['fad', 'chevron-up']"
    ></fa-icon>
  </button>
</div>
<div class="sub" *ngIf="hasSubRoutes && (showSubRoutes$ | async) === true">
  <a
    *ngFor="let subRoute of item.subRoutes; trackBy: trackByItem"
    class="link sub__link"
    [routerLink]="subRoute.route"
    routerLinkActive="link--active sub__link--active"
    [routerLinkActiveOptions]="{ exact: subRoute.route?.toString() === '/' }"
    (click)="clickLink(true)"
  >
    {{ subRoute.label }}
  </a>
</div>
