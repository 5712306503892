import {
  ClosingMotive,
  DossierFormat,
  DossierType,
  Office,
  SkillDomain,
  SkillProfile,
} from '@mychrono/models';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LoadResources } from '@stores/resources/resources.action';
import { Observable } from 'rxjs';
import { ResourcesWebservice } from '@webservices/resources.webservice';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { UserState } from '@stores/user/user.state';

export interface ResourcesStateModel {
  dossierTypes: DossierType[];
  dossierFormats: DossierFormat[];
  skillDomains: SkillDomain[];
  skillProfiles: SkillProfile[];
  offices: Office[];
  closingMotives: ClosingMotive[];
}

@State<ResourcesStateModel>({
  name: 'resources',
  defaults: {
    skillDomains: [],
    skillProfiles: [],
    dossierFormats: [],
    dossierTypes: [],
    offices: [],
    closingMotives: [],
  },
})
@Injectable()
export class ResourcesState implements NgxsOnInit {
  @Selector()
  static skillDomains(state: ResourcesStateModel): SkillDomain[] {
    return state.skillDomains;
  }

  @Selector()
  static skillProfiles(state: ResourcesStateModel): SkillProfile[] {
    return state.skillProfiles;
  }

  @Selector()
  static dossierFormats(state: ResourcesStateModel): DossierFormat[] {
    return state.dossierFormats;
  }

  @Selector()
  static dossierTypes(state: ResourcesStateModel): DossierType[] {
    return state.dossierTypes;
  }

  @Selector()
  static closingMotives(state: ResourcesStateModel): ClosingMotive[] {
    return state.closingMotives;
  }

  @Selector()
  static offices(state: ResourcesStateModel): Office[] {
    return state.offices;
  }

  constructor(
    private readonly resourcesWebservice: ResourcesWebservice,
    private readonly store: Store
  ) {}

  ngxsOnInit(ctx: StateContext<any>) {
    this.store
      .select(UserState.user)
      .pipe(
        filter((id) => !!id),
        first(),
        switchMap(() => ctx.dispatch(new LoadResources()))
      )
      .subscribe();
  }

  @Action(LoadResources)
  loadResources({
    setState,
  }: StateContext<ResourcesStateModel>): Observable<any> {
    return this.resourcesWebservice.getAllResources().pipe(
      tap((res) => {
        setState({
          dossierTypes: res.dossierTypes ?? [],
          dossierFormats: res.dossierFormats ?? [],
          skillDomains: res.skillDomains ?? [],
          skillProfiles: res.skillProfiles ?? [],
          offices: res.offices ?? [],
          closingMotives: res.closingMotives ?? [],
        });
      })
    );
  }
}
