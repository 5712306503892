import { Resource } from './common.interface';
import { RecursivePartial, RoleEnum, User } from './user.interface';
import { hasMinimalRole } from './util';
import { Project } from './project.interface';

export interface ProjectComment extends Resource {
  creatorId: string;
  projectId: string;
  content: string;
}

export type ProjectCommentCreate = Omit<
  RecursivePartial<ProjectComment>,
  keyof Resource
>;

export interface ProjectCommentFindParams {
  limit?: number;
  skip?: number;
  total?: number;
  sortField?: keyof ProjectComment | string;
  sortOrder?: 1 | -1;
  projectId?: string;
}

export function canDeleteProjectComment(
  comment: ProjectComment,
  project: Project,
  user: User
): boolean {
  if (hasMinimalRole(RoleEnum.Admin, user)) {
    return true;
  }

  if (comment.creatorId.toString() === user._id.toString()) {
    return true;
  }

  return false;
}
