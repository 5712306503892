import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'nca-app-logo',
  templateUrl: './app-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app-logo.component.scss'],
})
export class AppLogoComponent {
  @Input() height = 67;
}

@NgModule({
  declarations: [AppLogoComponent],
  exports: [AppLogoComponent],
})
export class AppLogoModule {}
