import { NavMenuItem } from '@mychrono/ui-components';

export const DashboardNavItem: NavMenuItem = {
  route: ['/'],
  label: 'Accueil',
  icon: 'home',
};
export const ExternDossiersNavItem: NavMenuItem = {
  route: ['/', 'dossiers', 'externe'],
  label: 'Vos dossiers',
  icon: 'folders',
};
export const InternDossiersNavItem: NavMenuItem = {
  route: ['/', 'dossiers', 'interne'],
  label: 'Dossiers',
  icon: 'folders',
};
export const ProjectsNavItem: NavMenuItem = {
  route: ['/', 'projets'],
  label: 'Projets',
  icon: 'books',
};
export const ClientsNavItem: NavMenuItem = {
  route: ['/', 'clients'],
  label: 'Clients',
  icon: 'user-tie',
};
export const ExternsNavItem: NavMenuItem = {
  route: ['/', 'externes'],
  label: 'Externes',
  icon: 'people-group',
};
export const AdministrationNavItem: NavMenuItem = {
  route: ['/', 'admin'],
  label: 'Administration',
  icon: 'shield-quartered',
  clickable: false,
  subRoutes: [
    {
      route: ['/', 'admin', 'users'],
      label: 'Utilisateurs',
    },
    {
      route: ['/', 'admin', 'bureaux'],
      label: 'Bureaux',
    },
    {
      route: ['/', 'admin', 'poles-metiers'],
      label: 'Pôles métiers',
    },
    {
      route: ['/', 'admin', 'types-de-dossiers'],
      label: 'Types de dossier',
    },
    {
      route: ['/', 'admin', 'formats-de-dossiers'],
      label: 'Formats de dossier',
    },
    {
      route: ['/', 'admin', 'motifs-de-cloture'],
      label: 'Motifs de clôture',
    },
    {
      route: ['/', 'admin', 'profils-de-competences'],
      label: 'Profils de compétences',
    },
  ],
};

export const UserNavItem: NavMenuItem = {
  route: ['/', 'profil'],
  label: 'Votre compte',
  icon: 'circle-user',
};
export const TemplatesNavItem: NavMenuItem = {
  route: ['/', 'modeles'],
  label: 'Modèles',
  icon: 'paintbrush-pencil',
};
export const FollowUpNavItem: NavMenuItem = {
  route: ['/', 'suivi'],
  label: 'Suivi',
  icon: 'flag-swallowtail',
};
