<nav class="menu">
  <div class="menu--mobile">
    <button (click)="showMenu(itemList)" class="menu--mobile__btn btn btn-icon">
      <fa-icon
        [icon]="['fal', 'bars']"
        class="menu--mobile__btn__icon"
      ></fa-icon>
    </button>

    <a class="menu--mobile__logo" routerLink="/">
      <ng-container [ngTemplateOutlet]="logo"></ng-container>
    </a>
  </div>

  <div class="menu--desktop">
    <ng-container [ngTemplateOutlet]="itemList"></ng-container>
  </div>
</nav>

<ng-template #itemList>
  <button
    class="close-btn btn btn-sm btn-icon"
    type="button"
    role="button"
    (click)="linkClicked()"
  >
    <fa-icon [icon]="['fal', 'xmark']" size="2x"></fa-icon>
  </button>

  <a
    class="menu__logo"
    role="presentation"
    (click)="linkClicked()"
    routerLink="/"
  >
    <ng-container [ngTemplateOutlet]="logo"></ng-container>
  </a>

  <ul class="menu__list">
    <li
      class="menu__list__item"
      *ngFor="let item of menu; trackBy: trackByItem"
    >
      <nca-nav-menu-item
        [item]="item"
        [currentRoute]="currentRoute"
        (linkClicked)="linkClicked()"
      ></nca-nav-menu-item>
    </li>

    <div class="menu__list__spacer"></div>

    <li *ngIf="profile" class="menu__list__item profile">
      <nca-nav-menu-item
        [item]="profile"
        [currentRoute]="currentRoute"
        (linkClicked)="linkClicked()"
      ></nca-nav-menu-item>
    </li>

    <li class="menu__list__item logout">
      <button
        (click)="this.linkClicked(); logout.emit()"
        class="btn btn-light btn-sm logout__btn"
      >
        <fa-icon
          class="btn-icon logout__btn__icon"
          icon="person-to-door"
        ></fa-icon>
        Se déconnecter
      </button>
    </li>
  </ul>
</ng-template>

<ng-template #logo>
  <img src="assets/my-chrono_logo.png" class="logo-img" />
</ng-template>
