import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavMenu, NavMenuItem } from './nav-menu.interface';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Router, RouterModule } from '@angular/router';
import { AppLogoModule } from '../app-logo/app-logo.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NavMenuItemComponent } from './nav-menu-item/nav-menu-item.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'nca-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuComponent implements OnInit {
  @Input() menu!: NavMenu;
  @Input() profile?: NavMenuItem;
  @Output() logout = new EventEmitter();

  modal?: NgbModalRef;

  constructor(
    private readonly modalService: NgbModal,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    if (!this.menu || !this.menu.length) {
      throw new Error('Invalid nav-menu configuration');
    }
  }

  trackByItem(_: number, item: NavMenuItem): string {
    return (item.route ?? [item.label]).join();
  }

  get currentRoute(): string {
    return this.router.url;
  }

  linkClicked(): void {
    if (this.modal) {
      this.modal.close();
    }
  }

  showMenu(menu: TemplateRef<any>): void {
    this.modal = this.modalService.open(menu, {
      windowClass: 'nav-menu-modal',
    });

    this.modal.closed.pipe(untilDestroyed(this)).subscribe(() => {
      this.modal = undefined;
    });
  }
}

@NgModule({
  declarations: [NavMenuComponent, NavMenuItemComponent],
  exports: [NavMenuComponent],
  imports: [CommonModule, FontAwesomeModule, RouterModule, AppLogoModule],
})
export class NavMenuModule {}
