import { NgModule } from '@angular/core';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faAlignLeft as faAlignLeftDuo,
  faArrowDown,
  faArrowRight,
  faArrowsRepeat,
  faArrowUp,
  faArrowUpToLine,
  faBadgeCheck,
  faBell,
  faBook,
  faBooks,
  faBoxArchive,
  faBoxOpen,
  faBriefcase,
  faCalendarDay,
  faCalendarDay as faCalendarDayDuo,
  faCalendarExclamation,
  faChartNetwork,
  faCheck,
  faCheckDouble,
  faChevronLeft,
  faChevronRight,
  faChevronUp as faChevronUpNotLight,
  faCircleCheck,
  faCircleEnvelope,
  faCircleExclamation,
  faCircleInfo as faCircleInfoNotLight,
  faCircleMinus,
  faCirclePlus,
  faCircleUser,
  faCircleXmark,
  faClipboardList,
  faCommentsQuestion,
  faCopy,
  faDownload as faDownloadDuo,
  faEnvelope,
  faEnvelopeDot,
  faEnvelopeOpenText,
  faExclamation,
  faEye,
  faEyeLowVision,
  faFileCircleCheck,
  faFileCircleExclamation,
  faFiles as faFilesDuo,
  faFileZipper,
  faFilters,
  faFlag as faFlagDuo,
  faFlagCheckered,
  faFlaskVial,
  faFolder,
  faFolderBookmark,
  faFolderMagnifyingGlass,
  faFolderOpen,
  faFolderPlus,
  faFolders,
  faFolderXmark,
  faForward,
  faGear,
  faHandPointer as faHandPointerDuo,
  faHardDrive,
  faHome,
  faIslandTropical,
  faLifeRing,
  faLink,
  faLinkSimpleSlash,
  faLinkSlash,
  faListCheck,
  faLock,
  faLockKeyhole,
  faMagnifyingGlass,
  faMessageCheck,
  faNotes,
  faPaintbrushPencil,
  faPaperclip,
  faPaperPlane,
  faPaperPlaneTop,
  faPencilAlt,
  faPeopleGroup,
  faPersonDigging,
  faPersonToDoor,
  faPhone,
  faPlus,
  faRightLeft,
  faRocketLaunch,
  faShieldCheck,
  faShieldQuartered,
  faSpinner,
  faSquare,
  faSquareCheck as faSquareCheckDuo,
  faStar,
  faTimer,
  faTrashAlt,
  faTriangleExclamation,
  faUser,
  faUserAlt,
  faUserCircle as faUserCircleDuo,
  faUserGear,
  faUserGroup,
  faUserHair,
  faUserHelmetSafety,
  faUserPlus,
  faUserShield,
  faUserTag,
  faUserTie,
  faUserXmark,
  faWifi,
  faXmark as faXmarkDuo,
  faXmarkLarge,
  faFlagSwallowtail as faFlagSwallowtailDuo,
  faQuestion, faArrowRotateLeft,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faAlarmClock,
  faAlignLeft,
  faArrowUpRightFromSquare,
  faBars,
  faBolt,
  faBook as faBookLight,
  faBookBookmark,
  faCalendar,
  faCalendarCircleExclamation,
  faCalendarClock,
  faCalendarDay as faCalendarDayLight,
  faCheckDouble as faCheckDoubleLight,
  faChevronDown,
  faChevronUp,
  faCircleCheck as faCircleCheckLight,
  faCircleChevronUp,
  faCircleEnvelope as faCircleEnvelopeLight,
  faCircleInfo,
  faCircleXmark as faCircleXmarkLight,
  faDownload,
  faEllipsis,
  faEnvelopeDot as faEnvelopeDotLight,
  faEye as faEyeLight,
  faFile,
  faFileArrowDown,
  faFiles,
  faFlag,
  faFlagSwallowtail,
  faFloppyDisk,
  faFolder as faFolderLight,
  faFolderOpen as faFolderOpenDuo,
  faFolders as faFoldersLight,
  faForward as faForwardLight,
  faGear as faGearLight,
  faGlasses,
  faHandPointer,
  faHourglass,
  faHourglassClock,
  faHourglassEnd,
  faHourglassStart,
  faLandmark,
  faMessageLines,
  faMinus,
  faPaperclip as faPaperclipLight,
  faPencilAlt as faPencilAltLight,
  faPenRuler, faPenToSquare,
  faPlus as faPlusLight,
  faSquareCheck,
  faStar as faStarLight,
  faTimer as faTimerLight,
  faTrashCan,
  faUser as faUserLight,
  faUserCircle as faUserCircleLight,
  faUserGroup as faUserGroupLight,
  faUsers as faUsersLight,
  faWandMagicSparkles,
  faXmark,
} from '@fortawesome/pro-light-svg-icons';

@NgModule()
export class IconsModule {
  constructor(
    private readonly iconLibrary: FaIconLibrary,
    private readonly iconConfig: FaConfig
  ) {
    iconConfig.defaultPrefix = 'fad';
    iconConfig.fixedWidth = true;
    iconLibrary.addIcons(
      faSquare,
      faShieldQuartered,
      faHome,
      faCircleUser,
      faFolders,
      faUserTie,
      faPeopleGroup,
      faBars,
      faPersonToDoor,
      faXmark,
      faChevronLeft,
      faChevronRight,
      faEnvelopeDot,
      faLinkSimpleSlash,
      faShieldCheck,
      faLockKeyhole,
      faBadgeCheck,
      faRocketLaunch,
      faUserShield,
      faUserGear,
      faMagnifyingGlass,
      faXmarkDuo,
      faPlus,
      faFilters,
      faFolderMagnifyingGlass,
      faEnvelope,
      faTriangleExclamation,
      faCircleExclamation,
      faEnvelopeOpenText,
      faCircleCheck,
      faSpinner,
      faBoxArchive,
      faTrashAlt,
      faPencilAlt,
      faPhone,
      faUserHair,
      faBriefcase,
      faUser,
      faEllipsis,
      faUserAlt,
      faUserPlus,
      faBoxOpen,
      faWifi,
      faUserXmark,
      faLock,
      faChartNetwork,
      faFolderOpen,
      faFolderPlus,
      faFolderXmark,
      faCircleMinus,
      faCirclePlus,
      faCircleXmark,
      faCircleEnvelope,
      faCircleEnvelopeLight,
      faCircleXmarkLight,
      faUserCircleLight,
      faGear,
      faPaperPlane,
      faCalendarDay,
      faListCheck,
      faUserGroup,
      faChevronUp,
      faChevronDown,
      faPaperclip,
      faFlagCheckered,
      faEye,
      faEyeLowVision,
      faCheckDouble,
      faArrowUpToLine,
      faCheck,
      faTimer,
      faExclamation,
      faClipboardList,
      faIslandTropical,
      faPersonDigging,
      faFileCircleExclamation,
      faFolder,
      faFolderBookmark,
      faLifeRing,
      faUserTag,
      faHourglassStart,
      faHourglassEnd,
      faBolt,
      faPenRuler,
      faEyeLight,
      faCalendar,
      faUserLight,
      faAlignLeft,
      faCircleCheckLight,
      faForward,
      faPaperclipLight,
      faCalendarDayLight,
      faCalendarCircleExclamation,
      faBell,
      faFolderLight,
      faHourglass,
      faUsersLight,
      faGearLight,
      faMessageLines,
      faPaperPlaneTop,
      faFile,
      faPencilAltLight,
      faFileCircleCheck,
      faCircleInfo,
      faArrowUpRightFromSquare,
      faXmarkLarge,
      faCircleInfoNotLight,
      faEnvelopeDotLight,
      faAlarmClock,
      faFiles,
      faDownload,
      faCommentsQuestion,
      faMessageCheck,
      faPaintbrushPencil,
      faHandPointer,
      faWandMagicSparkles,
      faFileArrowDown,
      faArrowUp,
      faArrowDown,
      faForwardLight,
      faCircleChevronUp,
      faFlaskVial,
      faCopy,
      faChevronUpNotLight,
      faBookBookmark,
      faLandmark,
      faUserGroupLight,
      faGlasses,
      faNotes,
      faFileZipper,
      faDownloadDuo,
      faFilesDuo,
      faRightLeft,
      faHourglassClock,
      faMinus,
      faPlusLight,
      faSquareCheck,
      faCheckDoubleLight,
      faTrashCan,
      faHardDrive,
      faAlignLeftDuo,
      faUserCircleDuo,
      faCalendarDayDuo,
      faHandPointerDuo,
      faFlag,
      faFolderOpenDuo,
      faTimerLight,
      faBooks,
      faFoldersLight,
      faBook,
      faBookLight,
      faUserHelmetSafety,
      faFlagDuo,
      faSquareCheckDuo,
      faCalendarExclamation,
      faLinkSlash,
      faLink,
      faArrowRight,
      faFloppyDisk,
      faArrowsRepeat,
      faStar,
      faStarLight,
      faFlagSwallowtail,
      faFlagSwallowtailDuo,
      faQuestion,
      faCalendarClock,
      faPenToSquare,
      faArrowRotateLeft,
    );
  }
}
