<span
  class="badge badge-sm badge-light"
  [ngClass]="'color-' + config.color"
  [class.badge-light-primary]="config.color === 'primary'"
  [class.badge-light-warning]="config.color === 'warning'"
  [class.badge-light-success]="config.color === 'success'"
  [class.badge-light-danger]="config.color === 'danger'"
  [class.badge-light]="config.color === 'gray-500'"
>
  {{ config.label }}
</span>
