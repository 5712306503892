import { PrivacyEnum, Resource } from './common.interface';
import { RecursivePartial, RoleEnum, User } from './user.interface';
import {
  hasMinimalRole,
  isCompleted,
  isDueDatePassed,
  isPassed,
  isStarted,
} from './util';
import { canEditDossier, Dossier } from './dossier.interface';
import { TaskComment } from './task-comments.interface';
import { TaskAttachment } from './task-attachments.interface';
import {
  differenceInBusinessDays,
  format,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isToday,
} from 'date-fns';
import { fr } from 'date-fns/locale';

export interface Task extends Resource {
  name: string;
  description: string;
  dossierId: string;
  type: TaskTypeEnum;
  creatorId: string;
  completerId: string;
  starterId?: string;
  parentTaskId?: string;
  templateTaskId?: string;
  templateParentTaskId?: string;
  dueAt?: string;
  dateSetMethod?: DateSetMethodEnum;
  deadlineAt?: string;
  publishedAt?: string;
  startedAt?: string;
  completedAt?: string;
  actorIds: string[];
  skillProfiles?: string[];
  settings: TaskSettings;
  completionOptions: {
    depositFileNames?: TaskDepotFileName[];
    filesPrivacy?: PrivacyEnum;
    allowDateUpdate?: boolean;
    askSpentTime?: boolean;
    spentTime?: number;
  };
  comments?: TaskComment[];
  attachments?: TaskAttachment[];
  followUp?: FollowUpEnum;
  dateUpdatedAt?: string;
  dateUpdaterId?: string;
}

export interface TaskSettings {
  privacy: PrivacyEnum;
  confirmationMethod: ConfirmationMethodEnum;
  allowAttendeeComments: boolean;
  allowAttendeeDeposits: boolean;
  allowNotifications: boolean;
  nbDaysBeforeDueNotification: number;
  allowDeadlineNotifications: boolean;
  allowLateNotifications: boolean;
  allowCompletionNotification: boolean;
  completionNotificationPrivacy: NotificationPrivacy;
  nbDaysBeforeDeadlineNotification: number;
  nbDaysAfterLateNotification: number;
}

export enum NotificationPrivacy {
  Managers = 'managers',
  Collaborators = 'collaborators',
  Attendees = 'attendees',
}

export interface TaskDepotFileName {
  filename: string;
  typeAccept?: string;
  mandatory?: boolean;
}

export enum TaskTypeEnum {
  Task = 'task',
  Jalon = 'jalon',
}

export enum FollowUpEnum {
  Intern = 'intern',
  InternAndClient = 'intern-and-client',
}

export enum ConfirmationMethodEnum {
  None = 'none',
  Manual = 'manual',
  Deposit = 'deposit',
}

export enum DateSetMethodEnum {
  Provisory = 'provisory', // la date a été définie provisoirement par le mécanisme d'import de modèle
  Manual = 'manual', // la date a été définie manuellement
  Calculated = 'calculated', // la date a été calculée automatiquement
  Forced = 'forced', // la date a été forcée par la confirmation d'un utilisateur
}

export const defaultTaskSettings: TaskSettings = {
  privacy: PrivacyEnum.Public,
  confirmationMethod: ConfirmationMethodEnum.Manual,
  allowAttendeeComments: false,
  allowAttendeeDeposits: false,
  allowNotifications: true,
  allowDeadlineNotifications: true,
  allowLateNotifications: false,
  nbDaysBeforeDueNotification: 7,
  nbDaysBeforeDeadlineNotification: 2,
  allowCompletionNotification: false,
  completionNotificationPrivacy: NotificationPrivacy.Managers,
  nbDaysAfterLateNotification: 1,
};

export const defaultJalonSettings: TaskSettings = {
  privacy: PrivacyEnum.Public,
  confirmationMethod: ConfirmationMethodEnum.Manual,
  allowAttendeeComments: false,
  allowAttendeeDeposits: false,
  allowNotifications: true,
  allowDeadlineNotifications: false,
  nbDaysBeforeDueNotification: 7,
  nbDaysBeforeDeadlineNotification: 2,
  allowCompletionNotification: false,
  completionNotificationPrivacy: NotificationPrivacy.Managers,
  allowLateNotifications: false,
  nbDaysAfterLateNotification: 1,
};

export const LabelByConfirmationMethod: Record<ConfirmationMethodEnum, string> =
  {
    [ConfirmationMethodEnum.None]: 'Confirmation automatique',
    [ConfirmationMethodEnum.Deposit]: 'Dépôt de documents',
    [ConfirmationMethodEnum.Manual]: 'Confirmation manuelle',
  };

export type TaskUpdate = Partial<RecursivePartial<Task>> &
  Pick<Resource, '_id'>;

export type TaskCreate = Omit<RecursivePartial<Task>, keyof Resource>;

export const TASK_SEARCHABLE_FIELDS: string[] = ['name', 'description'];

export function taskIsValid(task: Task): boolean {
  // Name & date are required
  if (!task.name || !task.dueAt) {
    return false;
  }

  // Actors required if confirmation method is not none
  if (
    task.settings.confirmationMethod !== ConfirmationMethodEnum.None &&
    task.actorIds.length === 0
  ) {
    return false;
  }

  // Deposit filenames are required if confirmation method is deposit
  if (
    task.settings.confirmationMethod === ConfirmationMethodEnum.Deposit &&
    task.completionOptions.depositFileNames?.length === 0
  ) {
    return false;
  }

  return true;
}

export function canSeeTask(task: Task, dossier: Dossier, user: User): boolean {
  if (task.actorIds.includes(user._id)) {
    return true;
  }
  if (task.settings.privacy === PrivacyEnum.Public) {
    return true;
  }
  if (task.settings.privacy === PrivacyEnum.Organization) {
    return hasMinimalRole(RoleEnum.Collaborator, user);
  }
  if (task.settings.privacy === PrivacyEnum.Admins) {
    return (
      hasMinimalRole(RoleEnum.Admin, user) ||
      dossier.creatorId === user._id ||
      dossier.managerIds.includes(user._id)
    );
  }
  return true;
}

export function canConfirmTask(
  task: Task,
  dossier: Dossier,
  user: User
): boolean {
  if (isCompleted(dossier) || task.completedAt) {
    return false;
  }
  if (task.settings.confirmationMethod === ConfirmationMethodEnum.None) {
    return false;
  }
  if (canEditDossier(dossier, user)) {
    return true;
  }
  if (task.actorIds.includes(user._id)) {
    return true;
  }
  return false;
}

export interface TaskStatusConfig {
  color: 'danger' | 'primary' | 'success' | 'warning' | 'gray-500';
  label: string;
  status: TaskStatusEnum;
}

export enum TaskStatusEnum {
  NotPlanned = 'not-planned',
  Incoming = 'incoming',
  WaitingForStart = 'waiting-for-start',
  OnGoing = 'onGoing',
  Over = 'over',
  Late = 'late',
}

export function getTaskStatus(task: Task, forClient = false): TaskStatusEnum {
  if (!task.dueAt) {
    return TaskStatusEnum.NotPlanned;
  }

  if (isCompleted(task)) {
    return TaskStatusEnum.Over;
  }

  if (task.settings.confirmationMethod === ConfirmationMethodEnum.None) {
    if (task.deadlineAt) {
      if (isPassed(task, 'deadlineAt')) {
        return TaskStatusEnum.Over;
      }
      if (isDueDatePassed(task)) {
        return TaskStatusEnum.OnGoing;
      }
      return TaskStatusEnum.Incoming;
    }

    if (isToday(new Date(task.dueAt))) {
      return TaskStatusEnum.OnGoing;
    } else if (isDueDatePassed(task)) {
      return TaskStatusEnum.Over;
    } else {
      return TaskStatusEnum.Incoming;
    }
  }

  if (task.deadlineAt) {
    if (isToday(new Date(task.deadlineAt))) {
      return TaskStatusEnum.OnGoing;
    }

    if (isPassed(task, 'deadlineAt')) {
      return forClient ? TaskStatusEnum.OnGoing : TaskStatusEnum.Late;
    }

    if (isStarted(task)) {
      return TaskStatusEnum.OnGoing;
    }

    if (isDueDatePassed(task)) {
      return forClient
        ? TaskStatusEnum.OnGoing
        : TaskStatusEnum.WaitingForStart;
    } else {
      return TaskStatusEnum.Incoming;
    }
  } else {
    if(isToday(new Date(task.dueAt))){
      return TaskStatusEnum.OnGoing;
    }

    if (isDueDatePassed(task)) {
      return task.type === TaskTypeEnum.Jalon
        ? TaskStatusEnum.Late
        : TaskStatusEnum.OnGoing;
    } else {
      return TaskStatusEnum.Incoming;
    }
  }
}

export function getTaskStatusConfig(
  task: Task,
  forClient = false
): TaskStatusConfig {
  const status = getTaskStatus(task);

  const getOnGoingConfig = (): TaskStatusConfig => ({
    label: 'En cours',
    color: 'primary',
    status,
  });

  switch (status) {
    case TaskStatusEnum.NotPlanned:
      return {
        color: 'gray-500',
        label: 'Non-planifié',
        status,
      };
    case TaskStatusEnum.Incoming:
      return {
        color: 'gray-500',
        label: `Commence dans ${formatDistanceToNowStrict(
          new Date(task.dueAt as string),
          { locale: fr }
        )}`,
        status,
      };
    case TaskStatusEnum.WaitingForStart:
      if (forClient) {
        return getOnGoingConfig();
      }
      return {
        color: 'warning',
        label: 'En attente',
        status,
      };
    case TaskStatusEnum.OnGoing:
      return getOnGoingConfig();
    case TaskStatusEnum.Over:
      return {
        color: 'success',
        label: 'Terminé',
        status,
      };
    case TaskStatusEnum.Late:
      if (forClient) {
        return getOnGoingConfig();
      }
      return {
        color: 'danger',
        label: 'En retard',
        status,
      };
  }
}

export function taskIsCompleted(task: Task): boolean {
  if (task.settings.confirmationMethod === ConfirmationMethodEnum.None) {
    return isPassed(task, 'dueAt');
  }
  if (task.completedAt) {
    return true;
  }
  return false;
}

export function formatTaskDate(
  task: Pick<Task, 'dueAt' | 'deadlineAt'>
): string | null {
  const currentDate = task.dueAt ? new Date(task.dueAt) : null;
  const deadlineDate = task.deadlineAt ? new Date(task.deadlineAt) : null;

  if (!currentDate) {
    return null;
  }

  if (deadlineDate) {
    if (
      isSameDay(currentDate, deadlineDate) ||
      Math.abs(differenceInBusinessDays(currentDate, deadlineDate)) <= 1
    ) {
      return format(currentDate, 'd MMMM yyyy', { locale: fr });
    }

    if (isSameMonth(currentDate, deadlineDate)) {
      return `Du ${format(currentDate, 'd', { locale: fr })} au ${format(
        deadlineDate,
        'd MMMM yyyy',
        { locale: fr }
      )}`;
    } else {
      return `Du ${format(currentDate, 'd MMMM ', {
        locale: fr,
      })} au ${format(deadlineDate, 'd MMMM yyyy', { locale: fr })}`;
    }
  } else {
    return format(currentDate, 'd MMMM yyyy', { locale: fr });
  }
}

export function getTasksMaxDate(tasks: Task[]): string | null {
  if (tasks.length === 0) {
    return null;
  }

  let max: Date | null = null;

  for (const task of tasks) {
    if (!max && task.dueAt) {
      max = new Date(task.dueAt);
    }

    if (task.dueAt) {
      const tDate = new Date(task.dueAt);
      if (max && isAfter(tDate, max)) {
        max = tDate;
      }
    }
    if (task.deadlineAt) {
      const tDate = new Date(task.deadlineAt);
      if (max && isAfter(tDate, max)) {
        max = tDate;
      }
    }
  }

  return max ? max.toISOString() : null;
}

export function getTasksMinDate(tasks: Task[]): string | null {
  if (tasks.length === 0) {
    return null;
  }

  let min: Date | null = null;

  for (const task of tasks) {
    if (!min && task.dueAt) {
      min = new Date(task.dueAt);
    } else {
      if (task.dueAt) {
        const tDate = new Date(task.dueAt);
        if (min && isBefore(tDate, min)) {
          min = tDate;
        }
      }
    }
  }

  return min ? min.toISOString() : null;
}

export interface TasksFindParams {
  limit?: number;
  skip?: number;
  total?: number;
  sortField?: keyof Task | string;
  sortOrder?: 1 | -1;
  completed?: boolean;
  actorId?: string;
  dossierId?: string;
  showNotDated?: boolean;
  published?: boolean;
  isCompleter?: boolean;
  skillProfile?: string;
}

export const DaysBeforeOptions: { label: string; value: number }[] = [
  {
    value: 1,
    label: '1 jour avant',
  },
  {
    value: 2,
    label: '2 jours avant',
  },
  {
    value: 3,
    label: '3 jours avant',
  },
  {
    value: 7,
    label: '1 semaine avant',
  },
  {
    value: 14,
    label: '2 semaines avant',
  },
];
export const DaysAfterOptions: { label: string; value: number }[] = [
  {
    value: 1,
    label: '1 jour après',
  },
  {
    value: 2,
    label: '2 jours après',
  },
  {
    value: 3,
    label: '3 jours après',
  },
  {
    value: 7,
    label: '1 semaine après',
  },
  {
    value: 14,
    label: '2 semaines après',
  },
];

export const NotificationPrivacyOptions: { label: string; value: string }[] = [
  {
    value: NotificationPrivacy.Managers,
    label: 'le responsable du dossier',
  },
  {
    value: NotificationPrivacy.Collaborators,
    label: 'tous les collaborateurs du dossier',
  },
  {
    value: NotificationPrivacy.Attendees,
    label: 'tous les participants du dossier',
  },
];
