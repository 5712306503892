import { Resource, ServiceEnum } from './common.interface';
import { RecursivePartial } from './user.interface';

export interface Activity extends Resource {
  type: ActivityTypeEnum;
  service: ServiceEnum;
  creatorId: string;
  resourceId: string;
  targetId: string;
  dossierId?: string;
}

export type ActivityCreate = Omit<RecursivePartial<Activity>, keyof Resource>;

export enum ActivityTypeEnum {
  ResourceCreation = 'resourceCreation',
  ResourceUpdate = 'resourceUpdate',
  ResourceArchivage = 'resourceArchivage',
  ResourceDeletion = 'resourceDeletion',
  ResourceUnarchivage = 'resourceUnarchivage',
  UserVerification = 'userVerification',
  UserConnection = 'userConnection',
  DossierPublication = 'dossierPublication',
  DossierEnd = 'dossierEnd',
  TaskCompletion = 'taskCompletion',
  CommentDeposit = 'commentDeposit',
  AttachmentDeposit = 'attachmentDeposit',
  ContactRequest = 'contactRequest',
}

export interface ActivityFindParams {
  types?: ActivityTypeEnum[];
  services?: ServiceEnum[];
  limit?: number;
  skip?: number;
  total?: number;
  sortField?: keyof Activity | string;
  sortOrder?: 1 | -1;
  targetId?: string;
}
