import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  CellFormatEnum,
  CellStyleEnum,
  ColumnConfig,
} from '../../list.interface';
import {
  ColorByDossierStatus,
  getDossierStatus,
  UserFindParams,
} from '@mychrono/models';
import { get } from 'lodash';

@Component({
  selector: 'nca-cell-view',
  templateUrl: './cell-view.component.html',
  styleUrls: ['./cell-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellViewComponent {
  @Input() rowData?: any;
  @Input() params?: UserFindParams;
  @Input() config!: ColumnConfig;

  CellFormat = CellFormatEnum;
  CellStyle = CellStyleEnum;

  get blankValue(): any {
    return (
      get(this.rowData, this.config.property) ??
      (this.config.altProperty
        ? get(this.rowData, this.config.altProperty)
        : this.config.altText)
    );
  }

  get booleanValue(): any {
    return Boolean(get(this.rowData, this.config.property));
  }

  get commaJoinValue(): string {
    return (get(this.rowData, this.config.property) as string[])?.join(', ');
  }

  get arrayValue(): any[] {
    return get(this.rowData, this.config.property) ?? [];
  }

  get dossierColorClass(): string {
    return (
      'badge-light-' + ColorByDossierStatus[getDossierStatus(this.rowData)]
    );
  }
}
