import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackToTopComponent } from './back-to-top.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [BackToTopComponent],
  exports: [BackToTopComponent],
  imports: [CommonModule, FontAwesomeModule],
})
export class BackToTopModule {}
