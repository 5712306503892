import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalContentBoxComponent } from './modal-content-box.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [ModalContentBoxComponent],
  imports: [CommonModule, NgbModalModule, FontAwesomeModule],
  exports: [ModalContentBoxComponent],
})
export class ModalContentBoxModule {}
