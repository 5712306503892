<div class="empty" [class.empty--sm]="small">
  <fa-duotone-icon
    class="empty__icon"
    icon="folder-magnifying-glass"
  ></fa-duotone-icon>

  <div class="empty__content">
    <div class="empty__content__title">
      {{ customTitle ?? 'Aucun résultat' }}
    </div>
    <div
      class="empty__content__subtitle"
      *ngIf="!customSubtitle; else customSub"
    >
      {{
        hasParams
          ? 'Votre recherche ne correspond à aucun'
          : 'La liste ne contient aucun'
      }}
      {{ itemWordSingular }}.
    </div>

    <ng-template #customSub>
      <div class="empty__content__subtitle">
        {{ customSubtitle }}
      </div>
    </ng-template>
  </div>
</div>
