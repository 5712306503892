import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicSpinnerComponent } from './basic-spinner/basic-spinner.component';

@NgModule({
  declarations: [BasicSpinnerComponent],
  imports: [CommonModule],
  exports: [BasicSpinnerComponent],
})
export class SpinnerModule {}
