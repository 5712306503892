import { Resource } from './common.interface';
import { RecursivePartial, RoleEnum, User } from './user.interface';
import { Task } from './task.interface';
import { hasMinimalRole, isCompleted } from './util';
import { Dossier } from './dossier.interface';

export interface TaskComment extends Resource {
  creatorId: string;
  taskId: string;
  dossierId: string;
  content: string;
  notifyManager?: boolean;
}

export type TaskCommentUpdate = Partial<RecursivePartial<TaskComment>> &
  Pick<Resource, '_id'>;

export type TaskCommentCreate = Omit<
  RecursivePartial<TaskComment>,
  keyof Resource
>;

export function canWriteComment(
  task: Task,
  dossier: Dossier,
  user: User
): boolean {
  if (
    task.actorIds.includes(user._id) ||
    dossier.creatorId === user._id ||
    dossier.managerIds.includes(user._id)
  ) {
    return true;
  }
  if (!task.settings.allowAttendeeComments) {
    return false;
  }
  if (dossier.attendeeIds.includes(user._id)) {
    return true;
  }
  return false;
}

export function canViewComments(
  task: Task,
  dossier: Dossier,
  user: User
): boolean {
  if (
    task.actorIds.includes(user._id) ||
    dossier.creatorId === user._id ||
    dossier.managerIds.includes(user._id)
  ) {
    return true;
  }
  if (hasMinimalRole(RoleEnum.Admin, user)) {
    return true;
  }
  if (!task.settings.allowAttendeeComments) {
    return false;
  }
  if (hasMinimalRole(RoleEnum.Collaborator, user)) {
    return true;
  }
  if (dossier.attendeeIds.includes(user._id)) {
    return true;
  }
  return false;
}

export function canUpdateDate(
  task: Task,
  dossier: Dossier,
  user: User
): boolean {
  if (task.completionOptions?.allowDateUpdate !== true) {
    return false;
  }

  if (isCompleted(dossier) || isCompleted(task)) {
    return false;
  }

  if (task.actorIds.map((i) => i.toString()).includes(user._id.toString())) {
    return true;
  }

  return false;
}

export function canBindComments(
  task: Task,
  dossier: Dossier,
  user: User
): boolean {
  if (
    task.actorIds.map((i) => i.toString()).includes(user._id.toString()) ||
    dossier.creatorId.toString() === user._id.toString() ||
    dossier.managerIds.map((i) => i.toString()).includes(user._id.toString())
  ) {
    return true;
  }
  if (hasMinimalRole(RoleEnum.Admin, user)) {
    return true;
  }
  if (!task.settings.allowAttendeeComments) {
    return false;
  }
  if (hasMinimalRole(RoleEnum.Collaborator, user)) {
    return true;
  }
  if (
    dossier.attendeeIds.map((i) => i.toString()).includes(user._id.toString())
  ) {
    return true;
  }
  return false;
}
