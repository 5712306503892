import { Resource } from './common.interface';
import { Dossier } from './dossier.interface';

export interface User extends Resource {
  email: string;
  password?: string;
  role: RoleEnum;
  fullName: string;
  creatorId?: string;
  officeId?: string;
  profile?: UserProfile;
  settings: UserSettings;
  permissions?: UserPermissions;
  archivedAt?: string;
  isClient?: boolean;
  isVerified: boolean;
  verifyToken: string;
  verifyExpires: Date;
  verifyChanges: any;
  resetToken: string;
  resetExpires: Date;
}

export interface ExtendedUser extends User {
  lastConnection?: Date;
  lastDossier?: Dossier;
}

export interface UserProfile {
  personType: PersonTypeEnum;
  firstname?: string;
  lastname?: string;
  title?: string;
  companyName?: string;
  phones: {
    label: string;
    phone: string;
  }[];
}

export enum RoleEnum {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  Collaborator = 'collaborator',
  Visitor = 'visitor',
}

export enum PersonTypeEnum {
  Physical = 'physical',
  Corporation = 'corporation',
}

export enum SignUpMethodEnum {
  WithPassword = 'with-password',
  Onboarding = 'onboarding',
}

export interface UserSettings {
  allowNotifications: boolean;
  allowReminderNotifications: boolean;
  allowDailyReport: boolean;
  signUpMethod: SignUpMethodEnum;
}

export interface UserPermissions {
  allowTemplateCreation: boolean;
  allowDossierCreation: boolean;
  allowProjectCreation: boolean;
  allowStatsFollowUp: boolean;
}

export type SkilledUser = User & { skillProfiles?: string[] };

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type UserUpdate = Partial<RecursivePartial<User>> &
  Pick<Resource, '_id'>;

export type UserCreate = Omit<RecursivePartial<User>, keyof Resource>;

export interface UserFindParams {
  searchValue?: string;
  limit?: number;
  skip?: number;
  total?: number;
  sortField?: keyof User | string;
  sortOrder?: 1 | -1;
  roles?: RoleEnum[];
  userType?: UserTypeEnum[];
  archived?: ArchivedFilter;
  creatorId?: string;
  officeId?: string;
  isCreator?: 'true' | 'false';
}

export interface ClientFindParams {
  searchValue?: string;
  limit?: number;
  skip?: number;
  total?: number;
  sortField?: keyof User | string;
  sortOrder?: 1 | -1;
  archived?: ArchivedFilter;
  creatorId?: string;
  isCreator?: 'true' | 'false';
}
export interface ExternalFindParams {
  searchValue?: string;
  limit?: number;
  skip?: number;
  total?: number;
  sortField?: keyof User | string;
  sortOrder?: 1 | -1;
  archived?: ArchivedFilter;
  creatorId?: string;
}

export type ArchivedFilter = 'all' | 'true' | 'false';

export const USER_SEARCHABLE_FIELDS: string[] = [
  'email',
  'profile.lastname',
  'profile.firstname',
  'profile.companyName',
];

export const LabelByRole: Record<RoleEnum | any, string> = {
  [RoleEnum.Visitor]: 'Visiteur',
  [RoleEnum.Collaborator]: 'Collaborateur',
  [RoleEnum.Admin]: 'Administrateur',
  [RoleEnum.SuperAdmin]: 'Super-administrateur',
};

export enum UserTypeEnum {
  Client = 'client',
  External = 'external',
  Collaborator = 'collaborator',
  Admin = 'admin',
}

export const RoleByUserType: Record<UserTypeEnum, RoleEnum> = {
  [UserTypeEnum.Client]: RoleEnum.Visitor,
  [UserTypeEnum.External]: RoleEnum.Visitor,
  [UserTypeEnum.Collaborator]: RoleEnum.Collaborator,
  [UserTypeEnum.Admin]: RoleEnum.Admin,
};
