import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchHighlight',
})
export class SearchHighlightPipe implements PipeTransform {
  transform(value: string, searchValue?: string): string {
    if (!searchValue) {
      return value;
    }

    if (Array.isArray(value)) {
      return value;
    }

    return value
      ?.toString()
      .replace(new RegExp(searchValue, 'gi'), '<mark>$&</mark>');
  }
}

@NgModule({
  declarations: [SearchHighlightPipe],
  exports: [SearchHighlightPipe],
})
export class SearchHighlightPipeModule {}
