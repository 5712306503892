import { TaskSettings } from './task.interface';
import {
  ArchivedFilter,
  RecursivePartial,
  RoleEnum,
  User,
} from './user.interface';
import { PrivacyEnum, Resource } from './common.interface';
import {
  hasMinimalRole,
  isArchived,
  isCompleted,
  isDeadlinePassed,
  isOpeningPassed,
  isPublished,
  isStarted,
} from './util';
import { SkillProfileMap } from './resources.interface';
import {
  format,
  formatDistanceToNowStrict,
  isSameDay,
  isSameMonth,
  isSameYear,
  isToday,
  parseISO,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Project } from './project.interface';

export interface Dossier extends Resource {
  name: string;
  description?: string;
  creatorId: string;
  clientIds: string[]; // liste des clients du dossier
  clientNames: string[]; // indexation du nom des clients (s/o fuzzy search)
  managerIds: string[]; // liste des gestionnaires du dossier
  attendeeIds: string[]; // liste de la totalité des participants
  passiveAttendeeIds: string[]; // liste des participants passifs (non-responsables de tâches)
  taskActorIds: string[]; // liste des participants responsables de tâches
  dossierFormat?: string;
  dossierType?: string;
  isVIP?: boolean;
  skillProfiles?: SkillProfileMap;
  settings: {
    allowNotifications: boolean;
    triggerNotificationOnPublish: boolean;
    onPublishNotificationIgnoredIds: string[];
  };
  layout?: {
    importedTemplateId?: string;
  };
  closingMotive?: string;
  closingActorId?: string;
  startedAt?: string;
  endAt?: string;
  completedAt?: string;
  publishedAt?: string;
  archivedAt?: string;

  openingDate?: string;
  deadlineDate?: string;

  officeId?: string;
  inotDossierId?: string;
  skillDomain?: string;
  projectId?: string;
}

export type FullDossier = Dossier & {
  attendees?: User[];
  project?: Project;
};

export type ExtendedDossier = Dossier & {
  taskCount: number;
  taskComplete: number;
  minDate: string;
  maxDate: string;
};

export function canEditDossier(
  dossier: Dossier,
  user: User,
  ignoreIsComplete = false
): boolean {
  if (!ignoreIsComplete) {
    if (isCompleted(dossier)) {
      return false;
    }
  }

  if (hasMinimalRole(RoleEnum.Admin, user)) {
    return true;
  }

  if (
    dossier.creatorId.toString() === user._id.toString() ||
    dossier.managerIds.map((i) => i.toString()).includes(user._id.toString())
  ) {
    return true;
  }
  return false;
}

export function canAttendLiveDossier(dossier: Dossier, user: User): boolean {
  if (isCompleted(dossier) || isArchived(dossier) || !dossier.startedAt) {
    return false;
  }
  if (canEditDossier(dossier, user)) {
    return true;
  }
  if (dossier.taskActorIds.includes(user._id)) {
    return true;
  }
  return false;
}

export function getDossierStatus(dossier: Dossier): DossierStatusEnum {
  if (isArchived(dossier)) {
    return DossierStatusEnum.Archived;
  }
  if (isCompleted(dossier)) {
    return DossierStatusEnum.Over;
  }
  if (!isPublished(dossier)) {
    return DossierStatusEnum.Draft;
  }
  if (dossier.deadlineDate) {
    if (isDeadlinePassed(dossier)) {
      return DossierStatusEnum.Late;
    }
  }
  if (dossier.openingDate) {
    if (isOpeningPassed(dossier)) {
      return DossierStatusEnum.OnGoing;
    } else {
      return DossierStatusEnum.Incoming;
    }
  }
  if (!isStarted(dossier)) {
    return DossierStatusEnum.Incoming;
  }
  return DossierStatusEnum.OnGoing;
}

export type DossierUpdate = Partial<RecursivePartial<Dossier>> &
  Pick<Resource, '_id'>;

export type DossierCreate = Omit<RecursivePartial<Dossier>, keyof Resource>;

export interface DossierStatusConfig {
  color: 'danger' | 'primary' | 'success' | 'warning' | 'gray-500';
  label: string;
  icon: string | IconProp | any;
  status: DossierStatusEnum;
}

export function getDossierStatusConfig(
  dossier: Dossier,
  forClient = false
): DossierStatusConfig | null {
  const status = getDossierStatus(dossier);
  const getOnGoingConfig = (): DossierStatusConfig => ({
    color: 'primary',
    icon: ['fal', 'bolt'],
    status,
    label: 'En cours de réalisation',
  });
  const getOverConfig = (
    closingMotive?: string | undefined
  ): DossierStatusConfig => ({
    color: 'success',
    icon: ['fal', 'hourglass-end'],
    status,
    label: `${
      closingMotive ? 'Clôturé' : 'Terminé'
    } depuis ${formatDistanceToNowStrict(
      parseISO(dossier.completedAt as string),
      { locale: fr }
    )}`,
  });

  switch (status) {
    case DossierStatusEnum.Archived:
      if (forClient) {
        return getOverConfig();
      }

      return {
        color: 'danger',
        icon: 'box-archive',
        status,
        label: `Archivé depuis ${formatDistanceToNowStrict(
          parseISO(dossier.archivedAt as string),
          { locale: fr }
        )}`,
      };
    case DossierStatusEnum.Over:
      return getOverConfig(forClient ? undefined : dossier.closingMotive);
    case DossierStatusEnum.Late:
      if (forClient) {
        return getOnGoingConfig();
      }

      return {
        color: 'warning',
        icon: ['fal', 'hourglass-clock'],
        status,
        label: `En retard de ${formatDistanceToNowStrict(
          parseISO(dossier.deadlineDate as string),
          { locale: fr }
        )}`,
      };
    case DossierStatusEnum.OnGoing:
      return getOnGoingConfig();
    case DossierStatusEnum.Incoming:
      // eslint-disable-next-line no-case-declarations
      const dateRef = parseISO(
        dossier.openingDate ?? (dossier.startedAt as string)
      );
      return {
        color: 'warning',
        icon: ['fal', 'hourglass-start'],
        status,
        label: isToday(dateRef)
          ? "Commence aujourd'hui"
          : `Commence dans ${formatDistanceToNowStrict(dateRef, {
              locale: fr,
            })}`,
      };
    case DossierStatusEnum.Draft:
      return {
        color: 'gray-500',
        status,
        icon: ['fal', 'pen-ruler'],
        label: `Brouillon`,
      };
    default:
      return null;
  }
}

export function formatExtendedDossierDate(
  dossier: ExtendedDossier
): string | null {
  const openingDate = dossier.minDate ? new Date(dossier.minDate) : null;
  const closingDate = dossier.maxDate ? new Date(dossier.maxDate) : null;

  if (!openingDate || !closingDate) {
    return null;
  }

  if (isSameDay(openingDate, closingDate)) {
    return format(openingDate, 'd MMMM yyyy', { locale: fr });
  }

  if (!isSameYear(openingDate, closingDate)) {
    return `Du ${format(openingDate, 'd MMMM yyyy', {
      locale: fr,
    })} au ${format(closingDate, 'd MMMM yyyy', { locale: fr })}`;
  }

  if (isSameMonth(openingDate, closingDate)) {
    return `Du ${format(openingDate, 'd', { locale: fr })} au ${format(
      closingDate,
      'd MMMM yyyy',
      { locale: fr }
    )}`;
  } else {
    return `Du ${format(openingDate, 'd MMMM ', {
      locale: fr,
    })} au ${format(closingDate, 'd MMMM yyyy', { locale: fr })}`;
  }
}

export function dossierCanBeClosed(dossier: Dossier): boolean {
  if (isCompleted(dossier)) {
    return false;
  }
  if (!isPublished(dossier)) {
    return false;
  }
  return true;
}

export enum DossierStatusEnum {
  Draft = 'draft',
  Incoming = 'incoming',
  OnGoing = 'onGoing',
  Over = 'over',
  Late = 'late',
  Archived = 'archived',
}

export const LabelByDossierStatus: Record<DossierStatusEnum, string> = {
  [DossierStatusEnum.Draft]: 'Brouillon',
  [DossierStatusEnum.Incoming]: 'À venir',
  [DossierStatusEnum.OnGoing]: 'En cours',
  [DossierStatusEnum.Over]: 'Terminé',
  [DossierStatusEnum.Archived]: 'Archivé',
  [DossierStatusEnum.Late]: 'En retard',
};

export const LabelByDossierStatusForClient: Record<DossierStatusEnum, string> =
  {
    [DossierStatusEnum.Draft]: 'Non-publié',
    [DossierStatusEnum.Incoming]: 'À venir',
    [DossierStatusEnum.OnGoing]: 'En cours',
    [DossierStatusEnum.Over]: 'Terminé',
    [DossierStatusEnum.Archived]: 'Terminé',
    [DossierStatusEnum.Late]: 'En cours',
  };

export const ColorByDossierStatus: Record<DossierStatusEnum, string> = {
  [DossierStatusEnum.Draft]: 'gray',
  [DossierStatusEnum.Incoming]: 'warning',
  [DossierStatusEnum.OnGoing]: 'primary',
  [DossierStatusEnum.Over]: 'success',
  [DossierStatusEnum.Archived]: 'danger',
  [DossierStatusEnum.Late]: 'danger',
};

export interface DossierFindParams {
  searchValue?: string;
  limit?: number;
  skip?: number;
  isVIP?: boolean;
  total?: number;
  projectId?: string;
  officeId?: string;
  sortField?: keyof Dossier | string;
  sortOrder?: 1 | -1;
  archived?: ArchivedFilter;
  showPrivateDossier?: DossierPrivacyFilter;
  status?: DossierStatusEnum | 'none';
  attendeeLevel?:
    | 'attendee'
    | 'manager'
    | 'client'
    | 'creator'
    | 'none'
    | 'task-actor';
  attendeeId?: string;
  liveOnly?: boolean;
}

export type DossierPrivacyFilter = ArchivedFilter;

export const DOSSIER_SEARCHABLE_FIELDS: string[] = [
  'name',
  'description',
  'clientNames',
];
