import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiStepFormComponent } from './multi-step-form.component';

@NgModule({
  declarations: [MultiStepFormComponent],
  exports: [MultiStepFormComponent],
  imports: [CommonModule],
})
export class MultiStepFormModule {}
