import { NgModule, Pipe, PipeTransform } from '@angular/core';
import {
  getDossierStatus,
  LabelByDossierStatus,
  LabelByDossierStatusForClient,
} from '@mychrono/models';

@Pipe({
  name: 'dossierStatus',
})
export class DossierStatusPipe implements PipeTransform {
  transform(obj: any, clientWording = false): string {
    return clientWording
      ? LabelByDossierStatusForClient[getDossierStatus(obj)]
      : LabelByDossierStatus[getDossierStatus(obj)];
  }
}

@NgModule({
  declarations: [DossierStatusPipe],
  exports: [DossierStatusPipe],
})
export class DossierStatusPipeModule {}
