import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayJoin',
})
export class ArrayJoinPipe implements PipeTransform {
  transform(
    sourceArray: string[],
    separator: string,
    lastSeparator?: string
  ): string {
    if (
      !sourceArray ||
      !Array.isArray(sourceArray) ||
      sourceArray.length === 0
    ) {
      return '';
    }
    return sourceArray.reduce(
      (text, value, i, array) =>
        text +
        (i < array.length - 1 ? separator : lastSeparator ?? separator) +
        value
    );
  }
}

@NgModule({
  declarations: [ArrayJoinPipe],
  exports: [ArrayJoinPipe],
  providers: [ArrayJoinPipe],
  imports: [CommonModule],
})
export class ArrayJoinPipeModule {}
