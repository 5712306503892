import { Routes } from '@angular/router';
import { IsLoggedGuard } from '@core/guards/is-logged.guard';
import { LoggedLayoutComponent } from '@commons/logged-layout/logged-layout.component';
import { IsMinAdminGuard } from '@core/guards/permissions/is-min-admin.guard';
import { IsMinCollaboratorGuard } from '@core/guards/permissions/is-min-collab.guard';
import { AllowFollowUpAccessGuard } from '@core/guards/permissions/allow-follow-up-access-guard.service';

export enum FeaturesRoutingEnum {
  Sign = 'sign',
  NotFound = '404',
  Unauthorized = '403',
  ApiUnavailable = '500',
  DeprecatedBrowser = 'navigateur-incompatible',
  Dashboard = '',
  UserProfile = 'profil',
  Clients = 'clients',
  Administration = 'admin',
  Externes = 'externes',
  Dossiers = 'dossiers',
  Templates = 'modeles',
  Projects = 'projets',
  FollowUp = 'suivi',
}

export const FeaturesRoutes: Routes = [
  {
    path: FeaturesRoutingEnum.Sign,
    loadChildren: () =>
      import('./sign/sign.module').then((module) => module.SignModule),
  },
  {
    path: '',
    component: LoggedLayoutComponent,
    canActivate: [IsLoggedGuard],
    children: [
      {
        path: FeaturesRoutingEnum.Dashboard,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: FeaturesRoutingEnum.UserProfile,
        loadChildren: () =>
          import('./user/user.module').then((module) => module.UserModule),
      },
      {
        path: FeaturesRoutingEnum.Projects,
        canActivate: [IsMinCollaboratorGuard],
        loadChildren: () =>
          import('./projects/project.module').then(
            (module) => module.ProjectModule
          ),
      },
      {
        path: FeaturesRoutingEnum.Dossiers,
        loadChildren: () =>
          import('./dossiers/dossier.module').then(
            (module) => module.DossierModule
          ),
      },
      {
        path: FeaturesRoutingEnum.Clients,
        canActivate: [IsMinCollaboratorGuard],
        loadChildren: () =>
          import('./clients/clients.module').then(
            (module) => module.ClientsModule
          ),
      },
      {
        path: FeaturesRoutingEnum.Externes,
        canActivate: [IsMinCollaboratorGuard],
        loadChildren: () =>
          import('./externs/externs.module').then(
            (module) => module.ExternsModule
          ),
      },
      {
        path: FeaturesRoutingEnum.Templates,
        canActivate: [IsMinCollaboratorGuard],
        loadChildren: () =>
          import('./templates/templates.module').then(
            (module) => module.TemplateModule
          ),
      },
      {
        path: FeaturesRoutingEnum.FollowUp,
        canActivate: [IsMinCollaboratorGuard, AllowFollowUpAccessGuard],
        loadChildren: () =>
          import('./follow-up/follow-up.module').then(
            (module) => module.FollowUpModule
          ),
      },
      {
        path: FeaturesRoutingEnum.Administration,
        canActivate: [IsMinAdminGuard],
        loadChildren: () =>
          import('./administration/administration.module').then(
            (module) => module.AdministrationModule
          ),
      },
    ],
  },
  {
    path: FeaturesRoutingEnum.NotFound,
    loadChildren: () =>
      import('./errors/not-found/not-found.module').then(
        (module) => module.NotFoundModule
      ),
  },
  {
    path: FeaturesRoutingEnum.ApiUnavailable,
    loadChildren: () =>
      import('./errors/server-error/server-error.module').then(
        (module) => module.ServerErrorModule
      ),
  },
  {
    path: FeaturesRoutingEnum.Unauthorized,
    loadChildren: () =>
      import('./errors/unauthorized/unauthorized.module').then(
        (module) => module.UnauthorizedModule
      ),
  },
  {
    path: FeaturesRoutingEnum.DeprecatedBrowser,
    loadChildren: () =>
      import('./errors/deprecated-browser/deprecated-browser.component').then(
        (module) => module.DeprecatedBrowserModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: FeaturesRoutingEnum.Dashboard,
  },
  {
    path: '**',
    redirectTo: FeaturesRoutingEnum.NotFound,
  },
];
