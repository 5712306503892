import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { getTaskStatusConfig, Task, TaskStatusConfig } from '@mychrono/models';

@Component({
  selector: 'nca-task-status-tag',
  templateUrl: './task-status-tag.component.html',
  styleUrls: ['./task-status-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskStatusTagComponent {
  @Input() task!: Task;
  @Input() forClient = false;

  get config(): TaskStatusConfig {
    return getTaskStatusConfig(this.task, this.forClient);
  }
}

@NgModule({
  declarations: [TaskStatusTagComponent],
  imports: [CommonModule],
  exports: [TaskStatusTagComponent],
})
export class TaskStatusTagModule {}
