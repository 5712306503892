<div
  class="layout d-flex justify-content-center"
  *ngIf="(isLogged$ | async) === true"
>
  <header class="layout__header card d-flex flex-column flex-row-auto">
    <nca-nav-menu
      *ngIf="menu$ | async as menu"
      class="layout__header__menu card-body"
      [profile]="profile"
      [menu]="menu"
      (logout)="logout()"
    ></nca-nav-menu>
  </header>

  <div class="d-flex flex-column flex-row-fluid">
    <div class="d-flex flex-row flex-column-fluid">
      <div class="layout__content container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
