import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastService } from './toast.service';
import { ToastsComponent } from './toasts/toasts.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [ToastsComponent],
  exports: [ToastsComponent],
  imports: [CommonModule, NgbToastModule, FontAwesomeModule],
  providers: [ToastService],
})
export class ToastsModule {}
