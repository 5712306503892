import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { Logout } from '@stores/authentication/authentication.action';
import { Router } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features.routing';
import { SignRoutingEnum } from '@features/sign/sign.routing';

@Component({
  selector: 'nca-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent implements OnInit {
  constructor(
    private readonly actions$: Actions,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.actions$.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.router.navigate([
        '/',
        FeaturesRoutingEnum.Sign,
        SignRoutingEnum.Login,
      ]);
    });
  }
}
