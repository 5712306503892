<span
  class="chip"
  [class.chip--xsmall]="size === 'xsmall'"
  [class.chip--small]="size === 'small'"
  [class.chip--medium]="size === 'medium'"
  [class.chip--large]="size === 'large'"
  [class.chip--xlarge]="size === 'xlarge'"
  [ngbTooltip]="tooltip"
  [disableTooltip]="!tooltip"
  tooltipClass="tooltip-small"
  container="body"
>
  <fa-duotone-icon
    secondaryOpacity="0.8"
    class="head__vip__btn__icon chip__icon"
    icon="star"
  ></fa-duotone-icon>
</span>
