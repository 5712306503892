import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { getUserTypeLabel, User } from '@mychrono/models';

@Pipe({
  name: 'userType',
})
export class UserTypePipe implements PipeTransform {
  transform(userObj: User, admin = true): string {
    return getUserTypeLabel(userObj, admin);
  }
}

@NgModule({
  declarations: [UserTypePipe],
  exports: [UserTypePipe],
})
export class UserTypePipeModule {}
