import { HookContext } from '@feathersjs/feathers';
import { RoleEnum } from './user.interface';
import { MethodEnum } from './common.interface';

export const AllAdmins: RoleEnum[] = [RoleEnum.SuperAdmin, RoleEnum.Admin];
export const AllOrganisation: RoleEnum[] = [
  RoleEnum.SuperAdmin,
  RoleEnum.Admin,
  RoleEnum.Collaborator,
];
export const AllRoles: RoleEnum[] = Object.values(RoleEnum);

export type PermissionConfig = Record<MethodEnum, Permission[]>;

export interface Permission {
  hook: 'before' | 'after';
  type: PermissionTypeEnum;
  targetedRoles?: RoleEnum[];
  conditions?: ControlCondition[];
  description?: string;
}

export enum PermissionTypeEnum {
  Opened = 'opened', // method is allowed for all (logged or not)
  Disabled = 'disabled', // method is not allowed
  RoleBased = 'role', // method is allowed for whitelisted roles
  Conditional = 'condition', // method is allowed if conditions are validated
}

export interface ControlCondition {
  sourceType: DataOriginEnum;
  sourceKey?: string;
  sourceValue?: any;
  sourceQueryToPerform?: (context: HookContext) => Promise<any>;
  targetType: DataOriginEnum;
  targetKey?: string;
  targetValue?: any;
  targetQueryToPerform?: (context: HookContext) => Promise<any>;
  comparisonType: ComparisonMethodEnum;
  forceCast?: boolean;
}

export enum ComparisonMethodEnum {
  Equality = 'equality',
  IsInArray = 'is-in-array',
  Contains = 'contains',
  Superior = 'superior', // Not implemented
  Inferior = 'inferior', // Not implemented
}

export enum DataOriginEnum {
  Value = 'value', // source is comparison value
  User = 'user', // source is user data (based on authentication result)
  Result = 'result', // source is request result object
  Data = 'data', // source is request body
  ResourceId = 'resource-id', // source is request id (in path)
  Params = 'params', // source is request params
  PerformQuery = 'perform-query', // performs a query
}
