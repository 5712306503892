import { getUserName, hasMinimalRole, RoleEnum, User } from '@mychrono/models';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserWebservice } from '@webservices/user.webservice';
import { Logout } from '@stores/authentication/authentication.action';
import { filter, first, switchMap } from 'rxjs/operators';
import { AuthenticationWebservice } from '@webservices/authentication.webservice';
import { RemoveUser, SetUser } from '@stores/user/user.action';
import { AuthenticationState } from '@stores/authentication/authentication.state';
import { environment } from '@environment';

export interface UserStateModel {
  user: User | null;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
  },
})
@Injectable()
export class UserState implements NgxsOnInit {
  @Selector()
  static user(state: UserStateModel): User | null {
    return state.user;
  }

  @Selector()
  static role(state: UserStateModel): RoleEnum | undefined {
    return state.user?.role;
  }

  @Selector()
  static userId(state: UserStateModel): string | undefined {
    return state.user?._id;
  }

  @Selector()
  static isAdmin(state: UserStateModel): boolean {
    if (!state.user) {
      return false;
    }
    return hasMinimalRole(RoleEnum.Admin, state.user);
  }

  @Selector()
  static isCollab(state: UserStateModel): boolean {
    if (!state.user) {
      return false;
    }
    return hasMinimalRole(RoleEnum.Collaborator, state.user);
  }

  @Selector()
  static userName(state: UserStateModel): string {
    return getUserName(state.user);
  }

  constructor(
    private readonly userService: UserWebservice,
    private readonly authService: AuthenticationWebservice,
    private readonly store: Store
  ) {}

  ngxsOnInit(ctx?: StateContext<UserStateModel>): void {
    this.store
      .select(AuthenticationState.userId)
      .pipe(
        filter((id) => !!id),
        first(),
        switchMap((id) => this.userService.getUser(id as string))
      )
      .subscribe({
        next: (user) => {
          ctx?.setState({ user });
        },
        error: () => {
          if (environment.production) {
            this.store.dispatch(new Logout());
          }
        },
      });
  }

  @Action(SetUser)
  setUser(ctx: StateContext<UserStateModel>, { payload }: SetUser) {
    return ctx.setState({ user: payload });
  }

  @Action(RemoveUser)
  removeUser(ctx: StateContext<UserStateModel>) {
    return ctx.setState({ user: null });
  }
}
