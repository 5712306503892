import { Routes } from '@angular/router';
import { AuthLayoutComponent } from '@commons/auth-layout/auth-layout.component';

export enum SignRoutingEnum {
  Login = 'login',
  ResetPassword = 'reset-password',
  Onboarding = 'onboarding',
  Verify = 'verify',
}

export const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: SignRoutingEnum.ResetPassword,
        loadChildren: () =>
          import('./reset-password/reset-password.module').then(
            (module) => module.ResetPasswordModule
          ),
      },
      {
        path: SignRoutingEnum.Login,
        loadChildren: () =>
          import('./login/login.module').then((module) => module.LoginModule),
      },
      {
        path: SignRoutingEnum.Onboarding,
        loadChildren: () =>
          import('./onboarding/onboarding.module').then(
            (module) => module.OnboardingModule
          ),
      },
      {
        path: SignRoutingEnum.Verify,
        loadChildren: () =>
          import('./verify/verify.module').then(
            (module) => module.VerifyModule
          ),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: SignRoutingEnum.Login,
      },
    ],
  },
];
