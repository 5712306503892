import {
  AuthenticationCreation,
  RefreshTokenResponse,
} from '@models/authentication.interface';
import { User } from '@mychrono/models';

export class Login {
  static readonly type = '[Authentication] Login';
  constructor(public payload: AuthenticationCreation) {}
}

export class Logout {
  static readonly type = '[Authentication] Logout';
}

export class UpdateUser {
  static readonly type = '[Authentication] Update user';
  constructor(public payload: Partial<User>) {}
}

export class UpdateAccessToken {
  static readonly type = '[Authentication] Update access token';
  constructor(public payload: RefreshTokenResponse) {}
}
