import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
} from '@angular/core';
import {
  ColorByDossierStatus,
  Dossier,
  getDossierStatus,
  isArchived,
} from '@mychrono/models';
import { CommonModule } from '@angular/common';
import { UserTypePipeModule } from '../pipes/user-type.pipe';
import { DossierStatusPipeModule } from '../pipes/dossier-status.pipe';

@Component({
  selector: 'nca-dossier-status-tag',
  templateUrl: './dossier-status-tag.component.html',
  styleUrls: ['./dossier-status-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DossierStatusTagComponent {
  @Input() dossier!: Dossier;
  @Input() forClient = false;

  get dossierColorClass(): string {
    const color = ColorByDossierStatus[getDossierStatus(this.dossier)];

    if (this.forClient && color === 'danger') {
      return isArchived(this.dossier)
        ? 'badge-light-success'
        : 'badge-light-primary';
    }
    return `badge-light-${color}`;
  }
}

@NgModule({
  declarations: [DossierStatusTagComponent],
  imports: [CommonModule, UserTypePipeModule, DossierStatusPipeModule],
  exports: [DossierStatusTagComponent],
})
export class DossierStatusTagModule {}
