import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListViewComponent } from './list-view/list-view.component';
import { CellViewComponent } from './list-view/cell-view/cell-view.component';
import { UserNamePipeModule } from '../pipes/user-name.pipe';
import { RouterModule } from '@angular/router';
import { PageRedirectPipeModule } from '../pipes/page-redirect.pipe';
import { UserTypePipeModule } from '../pipes/user-type.pipe';
import { SearchInputModule } from '../forms/search-input/search-input.component';
import { ListHeaderComponent } from './list-header/list-header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ListFooterComponent } from './list-footer/list-footer.component';
import {
  NgbPaginationModule,
  NgbPopoverModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectInputModule } from '../forms/select-input/select-input.component';
import { EmptyListComponent } from './list-view/empty-list/empty-list.component';
import { SearchHighlightPipeModule } from '../pipes/search-highlight.pipe';
import { UserRoleTagModule } from '../user-role-tag/user-role-tag.component';
import { IsArchivedPipeModule } from '../pipes/is-archived.pipe';
import { DossierStatusPipeModule } from '../pipes/dossier-status.pipe';
import { DossierStatusTagModule } from '../dossier-status-tag/dossier-status-tag.component';
import { DateFnsModule } from 'ngx-date-fns';
import { DateFormatPipeModule } from '../pipes/date-format.pipe';
import { ArrayJoinPipeModule } from '../pipes/string-array-join';
import { DateFromNowPipeModule } from '../pipes/date-from-now.pipe';
import { VipChipModule } from '../vip-chip/vip-chip.module';

@NgModule({
  declarations: [
    ListViewComponent,
    CellViewComponent,
    ListHeaderComponent,
    ListFooterComponent,
    EmptyListComponent,
  ],
  exports: [
    ListViewComponent,
    ListHeaderComponent,
    ListFooterComponent,
    EmptyListComponent,
  ],
  imports: [
    CommonModule,
    UserNamePipeModule,
    RouterModule,
    PageRedirectPipeModule,
    UserTypePipeModule,
    SearchInputModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    FontAwesomeModule,
    FontAwesomeModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgSelectModule,
    SelectInputModule,
    SearchHighlightPipeModule,
    UserRoleTagModule,
    IsArchivedPipeModule,
    DossierStatusPipeModule,
    DossierStatusTagModule,
    DateFnsModule,
    DateFormatPipeModule,
    ArrayJoinPipeModule,
    DateFromNowPipeModule,
    VipChipModule,
  ],
})
export class ListModule {}
