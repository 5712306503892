<ngb-toast
  class="toast"
  [animation]="false"
  *ngFor="let toast of toasts$ | async; trackBy: trackByToast"
  [autohide]="true"
  header=" "
  [delay]="toast.options?.delay || 5000"
  (hidden)="toastService.remove(toast)"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="getTemplate(toast)"></ng-template>
  </ng-template>
  <ng-template #text>
    <div class="layout">
      <fa-icon
        class="layout__icon"
        [ngClass]="toast.class ? 'text-' + toast.class : ''"
        *ngIf="toast.icon"
        [icon]="toast.icon"
      ></fa-icon>
      <div class="layout__content">
        <p class="layout__content__title" *ngIf="toast.header">
          {{ toast.header }}
        </p>
        <p class="layout__content__subtitle">{{ toast.content }}</p>
      </div>
    </div>
  </ng-template>
</ngb-toast>
