import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AllResources,
  ClosingMotive,
  ClosingMotiveUpdate,
  DossierFormat,
  DossierFormatCreate,
  DossierFormatUpdate,
  DossierType,
  DossierTypeCreate,
  DossierTypeUpdate,
  Office,
  OfficeCreate,
  OfficeUpdate,
  SkillDomain,
  SkillDomainCreate,
  SkillDomainUpdate,
  SkillProfile,
  SkillProfileCreate,
  SkillProfileUpdate,
} from '@mychrono/models';

class ClosingMotiveCreate {}

@Injectable({
  providedIn: 'root',
})
export class ResourcesWebservice {
  apiUrl = environment.apiUrl;

  constructor(private readonly http: HttpClient) {}

  getAllResources(): Observable<AllResources> {
    return this.http.get<AllResources>(`${this.apiUrl}/resources`);
  }

  createDossierType(data: DossierTypeCreate): Observable<DossierType> {
    return this.http.post<DossierType>(`${this.apiUrl}/dossier-types`, data);
  }

  createClosingMotive(data: ClosingMotiveCreate): Observable<ClosingMotive> {
    return this.http.post<ClosingMotive>(
      `${this.apiUrl}/closing-motives`,
      data
    );
  }

  createDossierFormat(data: DossierFormatCreate): Observable<DossierFormat> {
    return this.http.post<DossierFormat>(
      `${this.apiUrl}/dossier-formats`,
      data
    );
  }

  createOffice(data: OfficeCreate): Observable<Office> {
    return this.http.post<Office>(`${this.apiUrl}/offices`, data);
  }

  createSkillDomain(data: SkillDomainCreate): Observable<SkillDomain> {
    return this.http.post<SkillDomain>(`${this.apiUrl}/skill-domains`, data);
  }

  createSkillProfile(data: SkillProfileCreate): Observable<SkillProfile> {
    return this.http.post<SkillProfile>(`${this.apiUrl}/skill-profiles`, data);
  }

  updateSkillDomain(data: SkillDomainUpdate): Observable<SkillDomain> {
    return this.http.patch<SkillDomain>(
      `${this.apiUrl}/skill-domains/${data._id}`,
      data
    );
  }

  updateSkillProfile(data: SkillProfileUpdate): Observable<SkillProfile> {
    return this.http.patch<SkillProfile>(
      `${this.apiUrl}/skill-profiles/${data._id}`,
      data
    );
  }

  updateDossierType(data: DossierTypeUpdate): Observable<DossierType> {
    return this.http.patch<DossierType>(
      `${this.apiUrl}/dossier-types/${data._id}`,
      data
    );
  }

  updateClosingMotive(data: ClosingMotiveUpdate): Observable<ClosingMotive> {
    return this.http.patch<ClosingMotive>(
      `${this.apiUrl}/closing-motives/${data._id}`,
      data
    );
  }

  updateDossierFormat(data: DossierFormatUpdate): Observable<DossierFormat> {
    return this.http.patch<DossierFormat>(
      `${this.apiUrl}/dossier-formats/${data._id}`,
      data
    );
  }

  updateOffice(data: OfficeUpdate): Observable<Office> {
    return this.http.patch<Office>(`${this.apiUrl}/offices/${data._id}`, data);
  }

  deleteDossierType(id: string): Observable<DossierType> {
    return this.http.delete<DossierType>(`${this.apiUrl}/dossier-types/${id}`);
  }

  deleteClosingMotive(id: string): Observable<ClosingMotive> {
    return this.http.delete<ClosingMotive>(
      `${this.apiUrl}/closing-motives/${id}`
    );
  }

  deleteDossierFormat(id: string): Observable<DossierFormat> {
    return this.http.delete<DossierFormat>(
      `${this.apiUrl}/dossier-formats/${id}`
    );
  }

  deleteSkillDomain(id: string): Observable<SkillDomain> {
    return this.http.delete<SkillDomain>(`${this.apiUrl}/skill-domains/${id}`);
  }

  deleteSkillProfile(id: string): Observable<SkillProfile> {
    return this.http.delete<SkillProfile>(
      `${this.apiUrl}/skill-profiles/${id}`
    );
  }

  deleteOffice(id: string): Observable<Office> {
    return this.http.delete<Office>(`${this.apiUrl}/offices/${id}`);
  }
}
