import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavMenu, NavMenuModule } from '@mychrono/ui-components';
import { RoleEnum, User } from '@mychrono/models';
import { distinctUntilChanged, Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';
import {
  AdministrationNavItem,
  ClientsNavItem,
  DashboardNavItem,
  ExternDossiersNavItem,
  ExternsNavItem,
  FollowUpNavItem,
  InternDossiersNavItem,
  ProjectsNavItem,
  TemplatesNavItem,
  UserNavItem,
} from '@commons/logged-layout/routes-config';
import { Logout } from '@stores/authentication/authentication.action';
import { UserState } from '@stores/user/user.state';
import { AuthenticationState } from '@stores/authentication/authentication.state';

@UntilDestroy()
@Component({
  templateUrl: './logged-layout.component.html',
  styleUrls: ['./logged-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoggedLayoutComponent implements OnInit {
  @Select(AuthenticationState.isLogged) isLogged$!: Observable<boolean>;

  menu$!: Observable<NavMenu>;
  readonly profile = UserNavItem;

  private readonly menuConfigs: Record<RoleEnum, NavMenu> = {
    [RoleEnum.SuperAdmin]: [
      DashboardNavItem,
      InternDossiersNavItem,
      ProjectsNavItem,
      TemplatesNavItem,
      FollowUpNavItem,
      ClientsNavItem,
      ExternsNavItem,
      AdministrationNavItem,
    ],
    [RoleEnum.Admin]: [
      DashboardNavItem,
      InternDossiersNavItem,
      ProjectsNavItem,
      TemplatesNavItem,
      FollowUpNavItem,
      ClientsNavItem,
      ExternsNavItem,
      AdministrationNavItem,
    ],
    [RoleEnum.Collaborator]: [
      DashboardNavItem,
      InternDossiersNavItem,
      ProjectsNavItem,
      TemplatesNavItem,
      ClientsNavItem,
      ExternsNavItem,
    ],
    [RoleEnum.Visitor]: [DashboardNavItem, ExternDossiersNavItem],
  };

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.menu$ = this.store.select(UserState.user).pipe(
      untilDestroyed(this),
      filter((user) => !!user),
      distinctUntilChanged(),
      map((user) => this.getMenuConfig(user as User))
    );
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }

  getMenuConfig(user: User): NavMenu {
    const config = this.menuConfigs[user.role];

    if (
      user.role === RoleEnum.Collaborator &&
      user.permissions?.allowStatsFollowUp === true
    ) {
      config.push(FollowUpNavItem);
    }

    return config;
  }
}

@NgModule({
  declarations: [LoggedLayoutComponent],
  imports: [CommonModule, RouterModule, NavMenuModule],
  exports: [LoggedLayoutComponent],
})
export class LoggedLayoutModule {}
