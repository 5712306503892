export interface ListViewConfig {
  columns: ColumnConfig[];
  redirection?: {
    paramKey: string;
    route: string[];
  };
}

export interface ColumnConfig {
  label: string;
  property: string;
  altProperty?: string;
  sortable?: boolean;
  width?: number;
  altText?: string;
  maxWidth?: number;
  format?: CellFormatEnum;
  style?: CellStyleEnum[];
  prefix?: {
    vip?: boolean;
  };
}

export enum CellFormatEnum {
  Boolean = 'boolean',
  YesNo = 'yes-no',
  Name = 'name',
  UserType = 'user-type',
  UserStatus = 'user-status',
  DossierStatus = 'dossier-status',
  CommaJoin = 'comma-join',
  Date = 'date',
  DateFromNow = 'date-from-now',
  ArrayCount = 'array-count',
  Color = 'color',
  CityLocation = 'city-location',
}

export enum CellStyleEnum {
  Primary = 'primary',
  Bold = 'bold',
  BigBold = 'big-bold',
  Badge = 'badge',
}
