import { Resource } from './common.interface';
import { RecursivePartial, RoleEnum, User } from './user.interface';
import { hasMinimalRole, isCompleted } from './util';
import { Dossier } from './dossier.interface';

export interface DossierComment extends Resource {
  creatorId: string;
  dossierId: string;
  content: string;
}

export type DossierCommentCreate = Omit<
  RecursivePartial<DossierComment>,
  keyof Resource
>;

export interface DossierCommentFindParams {
  limit?: number;
  skip?: number;
  total?: number;
  sortField?: keyof DossierComment | string;
  sortOrder?: 1 | -1;
  dossierId?: string;
}

export function canDeleteDossierComment(
  comment: DossierComment,
  dossier: Dossier,
  user: User
): boolean {
  if (hasMinimalRole(RoleEnum.Admin, user)) {
    return true;
  }

  if (isCompleted(dossier)) {
    return false;
  }

  if (comment.creatorId.toString() === user._id.toString()) {
    return true;
  }

  return false;
}
