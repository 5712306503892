import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VipChipComponent } from './vip-chip.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [VipChipComponent],
  imports: [CommonModule, FontAwesomeModule, NgbTooltip],
  exports: [VipChipComponent],
})
export class VipChipModule {}
