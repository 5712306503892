import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NavMenuItem } from '../nav-menu.interface';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'nca-nav-menu-item',
  templateUrl: './nav-menu-item.component.html',
  styleUrls: ['./nav-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuItemComponent implements OnInit {
  @Input() item!: NavMenuItem;
  @Input() currentRoute!: string;
  @Output() linkClicked = new EventEmitter<void>();

  showSubRoutes$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    if (this.hasSubRoutes && this.dadRouteIsActive) {
      this.showSubRoutes$.next(true);
    }
  }

  trackByItem(_: number, item: NavMenuItem): string {
    return (item.route ?? [item.label]).join();
  }

  get dadRouteIsActive(): boolean {
    const dadRoute = this.item.route?.join('/') ?? '/';
    const routeFormated = dadRoute.slice(1, dadRoute.length);
    return (
      this.currentRoute === routeFormated ||
      this.currentRoute.includes(routeFormated)
    );
  }

  get hasSubRoutes(): boolean {
    return this.item.subRoutes
      ? this.item.subRoutes && this.item.subRoutes.length > 0
      : false;
  }

  clickLink(fromSubRoute = false): void {
    this.linkClicked.emit();

    if (this.hasSubRoutes) {
      if (fromSubRoute) {
        this.showSubRoutes$.next(true);
      } else {
        if (this.showSubRoutes$.value) {
          this.showSubRoutes$.next(false);
        } else {
          this.showSubRoutes$.next(true);
          if (
            this.item.subRoutes &&
            this.item.subRoutes[0] &&
            this.item.subRoutes[0].route
          ) {
            this.router.navigate(this.item.subRoutes[0].route);
          }
        }
      }
    }
  }

  toggleSubRoutes(): void {
    this.showSubRoutes$.next(!this.showSubRoutes$.value);
  }
}
