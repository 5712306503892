import { Dossier } from './dossier.interface';
import { Activity } from './activity.interface';
import { Resource } from './common.interface';
import { RecursivePartial } from './user.interface';

export interface Notification extends Resource {
  targetId: string;
  type: NotificationTypeEnum;
  notified: boolean;
  sending?: {
    type: SendingTypeEnum;
    sendTime?: Date;
    sentAt?: Date;
  };
  content?: any;
}

export type NotificationCreate = Omit<
  RecursivePartial<Notification>,
  keyof Resource
>;

export enum NotificationTypeEnum {
  DailyReport = 'daily-report',
  IncomingTasks = 'incoming-tasks',
  DeadliningTasks = 'deadlining-tasks',
  LateTasks = 'late-tasks',
  PopUp = 'popup',
  EmailMessage = 'email-message',
}

export enum SendingTypeEnum {
  Instant = 'instant',
}

export interface ActivityByDossier {
  dossier: Dossier;
  activities: Activity[];
}

export interface EmailMessageContent {
  title?: string;
  content: string;
  emitter?: string;
}
