import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { Store } from '@ngxs/store';
import { filter, map } from 'rxjs/operators';
import { hasMinimalRole, RoleEnum } from '@mychrono/models';
import { FeaturesRoutingEnum } from '@features/features.routing';
import { UserState } from '@stores/user/user.state';

@Injectable()
export class IsMinAdminGuard implements CanActivate {
  constructor(private readonly store: Store, private readonly router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(UserState.role).pipe(
      filter((role) => !!role),
      map((role) => hasMinimalRole(RoleEnum.Admin, { role: role as RoleEnum })),
      switchMap((hasRole) => {
        if (!hasRole) {
          return this.router
            .navigate(['/', FeaturesRoutingEnum.Unauthorized], {
              skipLocationChange: true,
            })
            .then(() => false);
        }
        return of(true);
      })
    );
  }
}
