import { Task } from './task.interface';
import { Dossier } from './dossier.interface';
import { RoleEnum, User } from './user.interface';
import { PrivacyEnum, Resource } from './common.interface';
import { hasMinimalRole } from './util';

export interface TaskAttachment extends Resource {
  creatorId: string;
  taskId: string;
  dossierId: string;
  type: TaskAttachmentTypeEnum;
  fileName: string;
  fileId: string;
  format: string;
  size: number;
}

export interface TaskAttachmentCreate {
  taskId: string;
  dossierId: string;
  type?: TaskAttachmentTypeEnum;
  fileName: string;
}

export interface TaskAttachmentUpdate {
  _id: string;
  fileName: string;
}

export enum TaskAttachmentTypeEnum {
  Confirmation = 'confirmation',
  Comment = 'comment',
}

export interface TaskAttachmentFindParams {
  dossierId: string;
  sortField?: keyof TaskAttachment | string;
  sortOrder?: 1 | -1;
}

export interface FileObject {
  uri: string;
  id: string;
  size: number;
  contentType: string;
}

export function canDepositAttachment(
  task: Task,
  dossier: Dossier,
  user: User
): boolean {
  if (
    task.actorIds.includes(user._id) ||
    dossier.creatorId === user._id ||
    dossier.managerIds.includes(user._id)
  ) {
    return true;
  }
  if (!task.settings.allowAttendeeDeposits) {
    return false;
  }
  if (dossier.attendeeIds.includes(user._id)) {
    return true;
  }
  return false;
}

export function canViewAttachment(
  task: Task,
  dossier: Dossier,
  user: User
): boolean {
  if (
    task.actorIds.map((i) => i.toString()).includes(user._id.toString()) ||
    dossier.creatorId.toString() === user._id.toString() ||
    dossier.managerIds.map((i) => i.toString()).includes(user._id.toString())
  ) {
    return true;
  }
  if (!task.settings.allowAttendeeDeposits) {
    return false;
  }
  if (
    dossier.attendeeIds.map((i) => i.toString()).includes(user._id.toString())
  ) {
    return true;
  }
  return false;
}

/**
 * Basic filter of file attachment binding
 */
export function canBindAttachments(
  task: Task,
  dossier: Dossier,
  user: User
): boolean {
  if (
    task.actorIds.map((i) => i.toString()).includes(user._id.toString()) ||
    dossier.creatorId.toString() === user._id.toString() ||
    dossier.managerIds.map((i) => i.toString()).includes(user._id.toString()) ||
    dossier.clientIds.map((i) => i.toString()).includes(user._id.toString())
  ) {
    return true;
  }
  if (hasMinimalRole(RoleEnum.Admin, user)) {
    return true;
  }
  if (!task.settings.allowAttendeeDeposits) {
    return false;
  }
  if (hasMinimalRole(RoleEnum.Collaborator, user)) {
    return true;
  }
  if (
    dossier.attendeeIds.map((i) => i.toString()).includes(user._id.toString())
  ) {
    return true;
  }
  return false;
}

export function isAllowedToViewFile(
  attachment: TaskAttachment,
  task: Task,
  dossier: Dossier,
  user: User
): boolean {
  if (attachment.type === TaskAttachmentTypeEnum.Confirmation) {
    if (task.completionOptions.filesPrivacy === PrivacyEnum.Public) {
      return true;
    }
    if (task.completionOptions.filesPrivacy === PrivacyEnum.Organization) {
      return hasMinimalRole(RoleEnum.Collaborator, user);
    }

    if (task.completionOptions.filesPrivacy === PrivacyEnum.Admins) {
      return (
        hasMinimalRole(RoleEnum.Admin, user) ||
        user._id.toString() === dossier.creatorId.toString() ||
        dossier.managerIds
          .map((i) => i.toString())
          .includes(user._id.toString())
      );
    }
  } else if (task.settings.allowAttendeeDeposits) {
    return true;
  }

  return canViewAttachment(task, dossier, user);
}
