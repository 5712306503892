import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UserFindParams } from '@mychrono/models';
import { ColumnConfig, ListViewConfig } from '../list.interface';

@Component({
  selector: 'nca-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListViewComponent implements OnInit {
  @Input() data!: any[];
  @Input() config!: ListViewConfig;
  @Input() loading = false;
  @Input() rowsClickable = false;
  @Input() listParams!: UserFindParams;
  @Input() itemWordSingular = 'élément';
  @Output() updateParams = new EventEmitter<Partial<UserFindParams>>();
  @Output() rowClicked = new EventEmitter<any>();

  ngOnInit(): void {
    if (!this.config) {
      throw new Error('List-view config is required');
    }
  }

  trackByResource(_: number, resource: any): string {
    return resource._id;
  }

  trackByColumn(_: number, col: ColumnConfig): string {
    return col.property;
  }

  changeSort(column: ColumnConfig) {
    let sortOrder: 1 | -1 = 1;
    if (this.listParams.sortField === column.property) {
      sortOrder = this.listParams.sortOrder === 1 ? -1 : 1;
    }

    this.updateParams.emit({
      sortField: column.property,
      sortOrder,
      skip: 0,
    });
  }
}
