import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'toFormControl',
})
export class ToFormControlPipe implements PipeTransform {
  transform(control: AbstractControl): FormControl {
    return control as FormControl;
  }
}

@Pipe({
  name: 'toFormGroup',
})
export class ToFormGroupPipe implements PipeTransform {
  transform(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }
}

@NgModule({
  declarations: [ToFormControlPipe, ToFormGroupPipe],
  exports: [ToFormControlPipe, ToFormGroupPipe],
})
export class CastPipeModule {}
