import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Task } from '@mychrono/models';

@Pipe({
  name: 'filterByActor',
})
export class FilterByActorPipe implements PipeTransform {
  transform(
    items: Task[],
    userId: string,
    assignedSkillProfiles: string[],
    filter = false
  ): Task[] {
    return items.filter((task) => {
      if (!filter) {
        return true;
      }
      return task.actorIds.includes(userId);
    });
  }
}

@NgModule({
  declarations: [FilterByActorPipe],
  exports: [FilterByActorPipe],
})
export class FilterByActorPipeModule {}
