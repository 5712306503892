import { Resource } from './common.interface';
import { RecursivePartial } from './user.interface';

export interface Contact extends Resource {
  type: ContactTypeEnum;
  object?: string;
  content: string;
  creatorId: string;
  url?: string;
}

export type ContactCreate = Omit<
  RecursivePartial<Contact>,
  keyof Resource | 'creatorId'
>;

export enum ContactTypeEnum {
  Support = 'support',
  SupportSuggestion = 'support/suggestion',
  SupportInfo = 'support/info',
  SupportProblem = 'support/problem',
  SupportOther = 'support/other',
  Public = 'public',
}
export const LabelByContactType: Record<ContactTypeEnum, string> = {
  [ContactTypeEnum.SupportProblem]: 'Je rencontre des difficultés',
  [ContactTypeEnum.SupportInfo]: 'Je voudrais obtenir des informations',
  [ContactTypeEnum.SupportSuggestion]: 'Je souhaiterais faire une suggestion',
  [ContactTypeEnum.SupportOther]: 'Autre...',
  [ContactTypeEnum.Public]: 'Inconnu',
  [ContactTypeEnum.Support]: 'Inconnu',
};
