<ng-select
  [items]="items"
  [multiple]="multiple"
  [searchable]="false"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [notFoundText]="notFoundText ?? 'Aucun résultat'"
  class="selector"
  [placeholder]="placeholderText"
  [clearable]="clearable"
  [formControl]="valueControl"
>
  <ng-template ng-option-tmp let-item="item">
    {{ item[bindLabel] }}
  </ng-template>

  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    {{ placeholderText }}&nbsp;<span
      class="selector__multi-label badge badge-circle badge-sm badge-primary"
      >{{ items.length }}</span
    >
  </ng-template>
</ng-select>
