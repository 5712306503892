import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(obj: Date | string): string {
    if (!obj) {
      return '';
    }

    return format(obj instanceof Date ? obj : parseISO(obj), 'd MMMM yyyy', {
      locale: fr,
    });
  }
}

@NgModule({
  declarations: [DateFormatPipe],
  exports: [DateFormatPipe],
})
export class DateFormatPipeModule {}
