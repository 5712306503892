import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppLogoModule } from '@mychrono/ui-components';

@Component({
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLayoutComponent {}

@NgModule({
  declarations: [AuthLayoutComponent],
  imports: [CommonModule, RouterModule, AppLogoModule],
  exports: [AuthLayoutComponent],
})
export class AuthLayoutModule {}
