import {
  FullDossier,
  FullProject,
  Task,
  Template,
  User,
} from '@mychrono/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  SetDossierContent,
  SetProjectContent,
  SetTasksContent,
  SetTemplateContent,
  SetUserContent,
  UnsetDossierContent,
  UnsetProjectContent,
  UnsetTasksContent,
  UnsetTemplateContent,
  UnsetUserContent,
} from '@stores/content/content.action';

export interface ContentStateModel {
  currentUser?: User;
  currentDossier?: FullDossier;
  currentTasks?: Task[];
  currentTemplate?: Template;
  currentProject?: FullProject;
}

@State<ContentStateModel>({
  name: 'content',
  defaults: {},
})
@Injectable()
export class ContentState {
  @Selector()
  static currentUser(state: ContentStateModel): User | undefined {
    return state.currentUser;
  }
  @Selector()
  static currentDossier(state: ContentStateModel): FullDossier | undefined {
    return state.currentDossier;
  }
  @Selector()
  static currentProject(state: ContentStateModel): FullProject | undefined {
    return state.currentProject;
  }
  @Selector()
  static currentTemplate(state: ContentStateModel): Template | undefined {
    return state.currentTemplate;
  }
  @Selector()
  static currentTasks(state: ContentStateModel): Task[] | undefined {
    return state.currentTasks;
  }

  @Action(SetUserContent)
  setUser(
    { patchState }: StateContext<ContentStateModel>,
    { user }: SetUserContent
  ): void {
    patchState({
      currentUser: user,
    });
  }

  @Action(UnsetUserContent)
  unsetUser({ patchState }: StateContext<ContentStateModel>): void {
    patchState({
      currentUser: undefined,
    });
  }

  @Action(SetDossierContent)
  setDossier(
    { patchState }: StateContext<ContentStateModel>,
    { dossier }: SetDossierContent
  ): void {
    patchState({
      currentDossier: dossier,
    });
  }

  @Action(UnsetDossierContent)
  unsetDossier({ patchState }: StateContext<ContentStateModel>): void {
    patchState({
      currentDossier: undefined,
    });
  }

  @Action(SetProjectContent)
  setProject(
    { patchState }: StateContext<ContentStateModel>,
    { project }: SetProjectContent
  ): void {
    patchState({
      currentProject: project,
    });
  }

  @Action(UnsetProjectContent)
  unsetProject({ patchState }: StateContext<ContentStateModel>): void {
    patchState({
      currentProject: undefined,
    });
  }

  @Action(SetTemplateContent)
  setTemplate(
    { patchState }: StateContext<ContentStateModel>,
    { template }: SetTemplateContent
  ): void {
    patchState({
      currentTemplate: template,
    });
  }

  @Action(UnsetTemplateContent)
  unsetTemplate({ patchState }: StateContext<ContentStateModel>): void {
    patchState({
      currentTemplate: undefined,
    });
  }

  @Action(SetTasksContent)
  setTasks(
    { patchState }: StateContext<ContentStateModel>,
    { tasks }: SetTasksContent
  ): void {
    patchState({
      currentTasks: tasks,
    });
  }

  @Action(UnsetTasksContent)
  unsetTasks({ patchState }: StateContext<ContentStateModel>): void {
    patchState({
      currentTasks: undefined,
    });
  }
}
