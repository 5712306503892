import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
} from '@angular/core';
import { User } from '@mychrono/models';
import { CommonModule } from '@angular/common';
import { UserTypePipeModule } from '../pipes/user-type.pipe';

@Component({
  selector: 'nca-user-role-tag',
  templateUrl: './user-role-tag.component.html',
  styleUrls: ['./user-role-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRoleTagComponent {
  @Input() user?: User;
  @Input() showAdmin = true;
}

@NgModule({
  declarations: [UserRoleTagComponent],
  imports: [CommonModule, UserTypePipeModule],
  exports: [UserRoleTagComponent],
})
export class UserRoleTagModule {}
