import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { ListViewConfig } from '../list/list.interface';

@Pipe({
  name: 'pageRedirect',
})
export class PageRedirectPipe implements PipeTransform {
  transform(obj: any, config: ListViewConfig): string[] {
    return config.redirection
      ? ['/', ...config.redirection.route, obj[config.redirection.paramKey]]
      : [];
  }
}

@NgModule({
  declarations: [PageRedirectPipe],
  exports: [PageRedirectPipe],
})
export class PageRedirectPipeModule {}
