import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { isArchived } from '@mychrono/models';

@Pipe({
  name: 'isArchived',
})
export class IsArchivedPipe implements PipeTransform {
  transform(obj: { archivedAt?: string } | any): boolean {
    if (!obj.archivedAt) {
      return false;
    }
    return isArchived({ archivedAt: obj.archivedAt });
  }
}

@NgModule({
  declarations: [IsArchivedPipe],
  exports: [IsArchivedPipe],
})
export class IsArchivedPipeModule {}
