import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Logout } from '@stores/authentication/authentication.action';
import { ACCESS_REVOKED_ERROR_CODE } from '@mychrono/models';
import { ToastService } from '@mychrono/ui-components';

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly store: Store
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if ([0, 500].includes(error.status)) {
          this.toastService.show({
            content: 'Vous ne pouvez pas effectuer cette opération.',
            header: 'Une erreur a été rencontrée',
            icon: 'triangle-exclamation',
            class: 'danger',
          });
          return throwError(error);
        }

        // Logout if AccessRevokedError is raised
        if (
          [ACCESS_REVOKED_ERROR_CODE].includes(error.status) &&
          !request.url.includes('/authentication') &&
          !request.url.includes('/refresh-token')
        ) {
          this.toastService.show({
            content: 'Votre accès à la plaforme a expiré.',
            header: 'Une erreur a été rencontrée',
            icon: 'triangle-exclamation',
            class: 'danger',
          });
          this.store.dispatch(new Logout());
        }
        return throwError(error);
      })
    );
  }
}
