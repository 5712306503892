import { User } from '@mychrono/models';

export class SetUser {
  static readonly type = '[Authentication] Set user';
  constructor(public payload: User) {}
}

export class RemoveUser {
  static readonly type = '[Authentication] Remove user';
}
