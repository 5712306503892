import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AuthenticationCreation,
  AuthenticationResponse,
  RefreshTokenCreation,
  RefreshTokenResponse,
} from '@models/authentication.interface';
import { User, UserUpdate } from '@mychrono/models';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationWebservice {
  apiUrl = environment.apiUrl;
  authManagementRoute = 'authentication/management';

  constructor(private readonly http: HttpClient) {}

  loginWithCredentials(
    credentials: AuthenticationCreation
  ): Observable<AuthenticationResponse> {
    return this.http.post<AuthenticationResponse>(
      `${this.apiUrl}/authentication`,
      credentials
    );
  }

  refreshAccessToken(
    payload: RefreshTokenCreation
  ): Observable<RefreshTokenResponse> {
    return this.http.post<RefreshTokenResponse>(
      `${this.apiUrl}/refresh-token`,
      payload
    );
  }

  verifyEmail(token: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${this.authManagementRoute}`, {
      action: 'verifySignupLong',
      value: token,
    });
  }

  resendVerifyEmail(token: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${this.authManagementRoute}`, {
      action: 'resendVerifySignup',
      value: {
        verifyToken: token,
      },
    });
  }

  confirmOnboarding(token: string, password: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${this.authManagementRoute}`, {
      action: 'verifySignupSetPasswordLong',
      value: {
        token,
        password,
      },
    });
  }

  sendPasswordReset(email: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${this.authManagementRoute}`, {
      action: 'sendResetPwd',
      value: {
        email,
      },
    });
  }

  updateUser(user: UserUpdate): Observable<User> {
    return this.http.patch<User>(`${this.apiUrl}/users/${user._id}`, user);
  }

  resetPassword(
    password: string,
    token: string
  ): Observable<{ email: string }> {
    return this.http.post<{ email: string }>(
      `${this.apiUrl}/${this.authManagementRoute}`,
      {
        action: 'resetPwdLong',
        value: { password, token },
      }
    );
  }

  changePassword(
    email: string,
    oldPassword: string,
    password: string
  ): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/${this.authManagementRoute}`, {
      action: 'passwordChange',
      value: {
        user: { email },
        password,
        oldPassword,
      },
    });
  }
}
