<span
  *ngIf="user"
  [class.badge-light-danger]="
    showAdmin && (user.role === 'admin' || user.role === 'superadmin')
  "
  [class.badge-light-success]="
    (!showAdmin && (user.role === 'admin' || user.role === 'superadmin')) ||
    user.role === 'collaborator'
  "
  [class.badge-light-info]="user.role === 'visitor' && user.isClient !== true"
  [class.badge-light-warning]="
    user.role === 'visitor' && user.isClient === true
  "
  class="badge badge-sm"
>
  {{ user | userType: showAdmin }}
</span>
