export const ONBOARDING_PARAM_KEY = 'activate-token';
export const VERIFY_SIGNUP_PARAM_KEY = 'verify-token';
export const RESET_PASSWORD_PARAM_KEY = 'reset-token';
export const MULTIPLE_MIN_QUERY_KEY = '$maxIn';
export const FULL_DOSSIER_QUERY_KEY = '$fullDossier';
export const FULL_PROJECT_QUERY_KEY = '$fullProject';
export const DOSSIERS_INFO_QUERY_KEY = '$bindDossierData';
export const PROJECTS_INFO_QUERY_KEY = '$bindProjectData';
export const USERS_INFO_QUERY_KEY = '$bindUserData';
export const TASK_ATTACHMENT_QUERY_KEY = '$bindTaskAttachments';
export const INFOS_EDIT_PARAM_KEY = 'editInfos';
export const JALON_DATE_UPDATE_QUERY_KEY = '$jalonDaysShift';
export const ACCESS_REVOKED_ERROR_CODE = 412;
export const DEFAULT_SKILL_DOMAIN_COLOR = '#009ef7';
export const DEFAULT_TASK_HOUR = 9;
