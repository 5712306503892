import { catchError, map, switchMap } from 'rxjs/operators';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthenticationWebservice } from '@webservices/authentication.webservice';
import { Observable, throwError } from 'rxjs';
import {
  Login,
  Logout,
  UpdateAccessToken,
} from '@stores/authentication/authentication.action';
import { RemoveUser, SetUser } from '@stores/user/user.action';
import { User } from '@mychrono/models';

export interface AuthenticationStateFull {
  accessToken: string;
  refreshToken: string;
  userId: string;
  expirationDate: Date;
}

export type AuthenticationStateModel = AuthenticationStateFull | null;

@State<AuthenticationStateModel>({
  name: 'authentication',
  defaults: null,
})
@Injectable()
export class AuthenticationState {
  @Selector()
  static accessToken(state: AuthenticationStateModel): string | undefined {
    return state?.accessToken;
  }

  @Selector()
  static refreshToken(state: AuthenticationStateModel): string | undefined {
    return state?.refreshToken;
  }

  @Selector()
  static isLogged(state: AuthenticationStateModel): boolean {
    return !!state?.accessToken;
  }

  @Selector()
  static expiryDate(state: AuthenticationStateModel): Date | undefined {
    return state?.expirationDate;
  }

  @Selector()
  static userId(state: AuthenticationStateModel): string | undefined {
    return state?.userId;
  }

  constructor(
    private readonly authService: AuthenticationWebservice,
    private readonly store: Store
  ) {}

  @Action(Login)
  login(
    ctx: StateContext<AuthenticationStateModel>,
    action: Login
  ): Observable<any> {
    return this.authService.loginWithCredentials(action.payload).pipe(
      catchError((error) => {
        ctx.setState(null);
        return throwError(error);
      }),
      map((response) => {
        ctx.setState({
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
          userId: response.user._id,
          expirationDate: new Date(response.authentication.payload.exp * 1000),
        });
        return response.user;
      }),
      switchMap((user: User) => this.store.dispatch(new SetUser(user)))
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthenticationStateModel>): Observable<any> {
    ctx.setState(null);
    return this.store.dispatch(new RemoveUser());
  }

  @Action(UpdateAccessToken)
  updateAccessToken(
    ctx: StateContext<AuthenticationStateModel>,
    { payload }: UpdateAccessToken
  ): Observable<any> {
    ctx.patchState({
      accessToken: payload.accessToken,
      userId: payload.user._id,
    });

    return this.store.dispatch(new SetUser(payload.user));
  }
}
