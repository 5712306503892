import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { from, map, Observable, of, switchMap } from 'rxjs';
import {
  ApiFindResponse,
  ClientFindParams,
  MULTIPLE_MIN_QUERY_KEY,
  RoleEnum,
  User,
  USER_SEARCHABLE_FIELDS,
  UserCreate,
  UserFindParams,
  USERS_INFO_QUERY_KEY,
  UserUpdate,
} from '@mychrono/models';
import { catchError, tap } from 'rxjs/operators';
import { CacheContainer } from 'node-ts-cache';
import { MemoryStorage } from 'node-ts-cache-storage-memory';
import { Store } from '@ngxs/store';
import { UserState } from '@stores/user/user.state';

const userNameCache = new CacheContainer(new MemoryStorage());

@Injectable({
  providedIn: 'root',
})
export class UserWebservice {
  apiUrl = environment.apiUrl;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store
  ) {}

  private get userId(): string {
    return this.store.selectSnapshot(UserState.userId) as string;
  }

  getUsername(id: string): Observable<string | null> {
    return from(userNameCache.getItem<string | null | undefined>(id)).pipe(
      switchMap((cachedName) => {
        if (cachedName !== undefined) {
          return of(cachedName);
        }
        return this.getUser(id, { selectFields: ['fullName'] }).pipe(
          map((user) => user.fullName),
          catchError(() => of(null)),
          tap((name) => this.cacheUsername(id, name))
        );
      })
    );
  }

  cacheUsername(id: string, name: string | null) {
    userNameCache.setItem(id, name, { isCachedForever: true });
  }

  getUser(
    id: string,
    options?: { selectFields: (keyof User)[] }
  ): Observable<User> {
    let inlineParams;
    if (options?.selectFields.length) {
      inlineParams = this.buildInlineParam(options.selectFields);
    }
    return this.http
      .get<User>(
        `${this.apiUrl}/users/${id}${inlineParams ? '?' + inlineParams : ''}`
      )
      .pipe(tap((user) => this.cacheUsername(user._id, user.fullName)));
  }

  getUsers(
    params?: UserFindParams,
    options?: { bindUserData?: boolean }
  ): Observable<ApiFindResponse<User>> {
    return this.http
      .get<ApiFindResponse<User>>(`${this.apiUrl}/users`, {
        params: {
          ...this.buildUserQueryParams(params ?? {}),
          ...(options?.bindUserData === true && {
            [USERS_INFO_QUERY_KEY]: true,
          }),
        },
      })
      .pipe(
        tap((response) => {
          response.data?.forEach((user) =>
            this.cacheUsername(user._id, user.fullName)
          );
        })
      );
  }

  getClients(
    params?: ClientFindParams,
    options?: { bindUserData?: boolean }
  ): Observable<ApiFindResponse<User>> {
    return this.http
      .get<ApiFindResponse<User>>(`${this.apiUrl}/users`, {
        params: {
          ...this.buildClientQueryParams(params ?? {}, true),
          ...(options?.bindUserData === true && {
            [USERS_INFO_QUERY_KEY]: true,
          }),
        },
      })
      .pipe(
        tap((response) => {
          response.data?.forEach((user) =>
            this.cacheUsername(user._id, user.fullName)
          );
        })
      );
  }

  getOrganisationPeople(
    params?: UserFindParams
  ): Observable<ApiFindResponse<User>> {
    return this.http
      .get<ApiFindResponse<User>>(`${this.apiUrl}/users`, {
        params: {
          ...this.buildUserQueryParams({
            ...params,
            roles: [RoleEnum.Admin, RoleEnum.Collaborator],
          }),
        },
      })
      .pipe(
        tap((response) => {
          response.data?.forEach((user) =>
            this.cacheUsername(user._id, user.fullName)
          );
        })
      );
  }

  getExternals(
    params?: ClientFindParams,
    options?: { bindUserData?: boolean }
  ): Observable<ApiFindResponse<User>> {
    return this.http
      .get<ApiFindResponse<User>>(`${this.apiUrl}/users`, {
        params: {
          ...this.buildClientQueryParams(params ?? {}, false),
          ...(options?.bindUserData === true && {
            [USERS_INFO_QUERY_KEY]: true,
          }),
        },
      })
      .pipe(
        tap((response) => {
          response.data?.forEach((user) =>
            this.cacheUsername(user._id, user.fullName)
          );
        })
      );
  }

  updateUser(user: UserUpdate): Observable<User> {
    return this.http
      .patch<User>(`${this.apiUrl}/users/${user._id}`, user)
      .pipe(tap((user) => this.cacheUsername(user._id, user.fullName)));
  }

  detachUserFromOffice(userId: string): Observable<User> {
    return this.http.patch<User>(`${this.apiUrl}/users/${userId}`, {
      $unset: { officeId: '' },
      _id: userId,
    });
  }

  deleteUser(id: string): Observable<User> {
    return this.http.delete<User>(`${this.apiUrl}/users/${id}`);
  }

  createUser(user: UserCreate): Observable<User> {
    return this.http
      .post<User>(`${this.apiUrl}/users`, user)
      .pipe(tap((user) => this.cacheUsername(user._id, user.fullName)));
  }

  private buildInlineParam(fields: string[]): string {
    let val = '';
    fields.forEach((field, index) => {
      if (index < fields.length - 1) {
        val += `$select[]=${field}&`;
      } else {
        val += `$select[]=${field}`;
      }
    });
    return val;
  }

  private buildClientQueryParams(
    params: ClientFindParams,
    isClient: boolean
  ): any {
    return {
      ...this.buildUserQueryParams({ ...params }),
      role: RoleEnum.Visitor,
      isClient,
    };
  }

  private buildUserQueryParams(params: UserFindParams): any {
    const res: any = {
      $skip: params.skip ?? 0,
      $limit: params.limit ?? 10,
    };

    if (params.sortField) {
      if (params.sortField === 'profile.lastname') {
        res[`$sort[profile.lastname]`] = params.sortOrder;
        res[`$sort[profile.companyName]`] = params.sortOrder;
      } else if (params.sortField === 'role') {
        res[`$sort[role]`] = params.sortOrder;
        res[`$sort[isClient]`] = params.sortOrder;
      } else {
        res[`$sort[${params.sortField}]`] = params.sortOrder;
      }
    }

    if (params.searchValue) {
      for (let i = 0; i < USER_SEARCHABLE_FIELDS.length; i++) {
        const prop =
          '$or[' + i + '][' + USER_SEARCHABLE_FIELDS[i] + '][$search]';
        res[prop] = params.searchValue;
      }
    } else {
      if (params.archived !== 'all') {
        res[`archivedAt[$exists]`] = params.archived ?? 'false';
      }

      if (params.isCreator === 'true') {
        res['creatorId'] = this.userId;
      }

      if (params.officeId) {
        res['officeId'] = params.officeId;
      }
    }

    if (params.roles && params.roles.length) {
      res[`${MULTIPLE_MIN_QUERY_KEY}[role]`] = params.roles.join(',');
    }

    if (params.userType && params.userType.length) {
      res['isClient'] = false;
    }

    if (params.creatorId) {
      res['creatorId'] = params.creatorId;
    }

    return res;
  }
}
