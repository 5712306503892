import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { isPublished } from '@mychrono/models';

@Pipe({
  name: 'isPublished',
})
export class IsPublishedPipe implements PipeTransform {
  transform(obj: { publishedAt?: string } | any): boolean {
    if (!obj.publishedAt) {
      return false;
    }
    return isPublished({ publishedAt: obj.publishedAt });
  }
}

@NgModule({
  declarations: [IsPublishedPipe],
  exports: [IsPublishedPipe],
})
export class IsPublishedPipeModule {}
