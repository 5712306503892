import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { Toast, ToastService } from '../toast.service';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'nca-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastsComponent {
  constructor(readonly toastService: ToastService) {}

  get toasts$(): Observable<Toast[]> {
    return this.toastService.toastsAsObservable.pipe(untilDestroyed(this));
  }

  getTemplate(toast: Toast): TemplateRef<any> {
    return toast.content as TemplateRef<any>;
  }

  trackByToast(_: number, toast: Toast): string | TemplateRef<any> {
    return toast.content;
  }

  isTemplate(toast: Toast): boolean {
    return toast.content instanceof TemplateRef;
  }
}
