import {
  FullDossier,
  FullProject,
  Task,
  Template,
  User,
} from '@mychrono/models';

export class SetUserContent {
  static readonly type = '[Content] Set user';
  constructor(public user: User) {}
}

export class UnsetUserContent {
  static readonly type = '[Content] Unset user';
}

export class SetDossierContent {
  static readonly type = '[Content] Set dossier';
  constructor(public dossier: FullDossier) {}
}

export class UnsetDossierContent {
  static readonly type = '[Content] Unset dossier';
}

export class SetProjectContent {
  static readonly type = '[Content] Set project';
  constructor(public project: FullProject) {}
}

export class UnsetProjectContent {
  static readonly type = '[Content] Unset project';
}

export class SetTemplateContent {
  static readonly type = '[Content] Set template';
  constructor(public template: Template) {}
}

export class UnsetTemplateContent {
  static readonly type = '[Content] Unset template';
}

export class SetTasksContent {
  static readonly type = '[Content] Set tasks';
  constructor(public tasks: Task[]) {}
}

export class UnsetTasksContent {
  static readonly type = '[Content] Unset tasks';
}
