import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { RoleEnum } from '@mychrono/models';
import { filter } from 'rxjs/operators';
import { UserState } from '@stores/user/user.state';

interface RoleFlags {
  isSuperadmin: boolean;
  isAdmin: boolean;
  isCollaborator: boolean;
  isVisitor: boolean;
}

@Injectable()
export class RoleService {
  @Select(UserState.role) userRole$!: Observable<RoleEnum | undefined>;
  currentRole$ = new BehaviorSubject<RoleEnum | null>(null);
  roleFlags = new BehaviorSubject<RoleFlags>({
    isSuperadmin: false,
    isAdmin: false,
    isCollaborator: false,
    isVisitor: false,
  });

  constructor() {
    this.userRole$
      .pipe(
        filter((role) => !!role),
        distinctUntilChanged()
      )
      .subscribe((role) => {
        this.currentRole$.next(role as RoleEnum);
        this.roleFlags.next({
          isSuperadmin: role === RoleEnum.SuperAdmin,
          isAdmin: role === RoleEnum.Admin,
          isCollaborator: role === RoleEnum.Collaborator,
          isVisitor: role === RoleEnum.Visitor,
        });
      });
  }
}
