<span
  class="cell"
  [class.cell--primary]="config.style?.includes(CellStyle.Primary)"
  [class.cell--bold]="config.style?.includes(CellStyle.Bold)"
  [class.cell--big-bold]="config.style?.includes(CellStyle.BigBold)"
  [class.cell--badge]="
    config.style?.includes(CellStyle.Badge) ||
    config.format === CellFormat.UserType
  "
  [ngStyle]="config.maxWidth ? { whiteSpace: 'break-spaces' } : {}"
  [ngSwitch]="config.format"
>
  <nca-user-role-tag
    *ngSwitchCase="CellFormat.UserType"
    [user]="rowData"
  ></nca-user-role-tag>

  <ng-container *ngSwitchCase="CellFormat.YesNo">
    {{ booleanValue === true ? 'Oui' : 'Non' }}
  </ng-container>

  <ng-container *ngSwitchCase="CellFormat.Boolean">
    {{ booleanValue === true ? 'Vrai' : 'Faux' }}
  </ng-container>

  <ng-container *ngSwitchCase="CellFormat.Name">
    <span
      [innerHTML]="rowData | userName | searchHighlight : params?.searchValue"
    ></span>
  </ng-container>

  <ng-container *ngSwitchCase="CellFormat.Date">
    <span
      [innerHTML]="blankValue | dfnsParseIso | dfnsFormat : 'd MMMM yyyy'"
    ></span>
  </ng-container>

  <ng-container *ngSwitchCase="CellFormat.DateFromNow">
    {{ blankValue | dateFromNow }}
  </ng-container>

  <ng-container *ngSwitchCase="CellFormat.ArrayCount">
    {{ arrayValue.length }}
  </ng-container>

  <ng-container *ngSwitchCase="CellFormat.Color">
    <span
      class="color-preview"
      [ngStyle]="{ backgroundColor: blankValue }"
    ></span>
  </ng-container>

  <ng-container *ngSwitchCase="CellFormat.CityLocation">
    {{ blankValue['city'] }}&nbsp;
    <ng-container *ngIf="blankValue['postalCode'] as cp">
      ({{ cp }})
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="CellFormat.CommaJoin">
    <span
      [innerHTML]="
        blankValue
          | arrayJoin : ', ' : ' et '
          | searchHighlight : params?.searchValue
      "
    ></span>
  </ng-container>

  <ng-container *ngSwitchCase="CellFormat.UserStatus">
    <span class="text-danger" *ngIf="rowData | isArchived; else altStatus">
      <fa-icon icon="box-archive" class="me-1"></fa-icon>
      Archivé
    </span>

    <ng-template #altStatus>
      <ng-container *ngIf="!rowData.isVerified; else okStatus">
        <fa-icon
          icon="triangle-exclamation"
          class="text-warning me-1"
        ></fa-icon>
        Inscription non-confirmée
      </ng-container>
    </ng-template>

    <ng-template #okStatus>
      <fa-icon icon="circle-check" class="text-success me-1"></fa-icon>
      <ng-container *ngIf="rowData.lastConnection; else defaultStatus">
        Dernière connexion le
        {{ rowData.lastConnection | dfnsParseIso | dfnsFormat : 'dd/MM/yyyy' }}
      </ng-container>
      <ng-template #defaultStatus> Accès valide </ng-template>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="CellFormat.DossierStatus">
    <nca-dossier-status-tag [dossier]="rowData"></nca-dossier-status-tag>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <nca-vip-chip
      *ngIf="config.prefix?.vip && rowData.isVIP"
      style="margin-right: 6px"
      size="small"
    ></nca-vip-chip>
    <span [innerHTML]="blankValue | searchHighlight : params?.searchValue">
    </span>
  </ng-container>
</span>
