<input
  #searchInput
  [ngStyle]="{ minHeight: height, height: height }"
  class="searchbar form-control form-control-sm form-control-solid"
  [formControl]="searchValue"
  [placeholder]="placeholder ?? 'Rechercher...'"
/>
<fa-icon
  [ngStyle]="{ top: iconTop }"
  class="searchbar__icon"
  [class.searchbar__icon--active]="!!searchValue.value"
  icon="magnifying-glass"
></fa-icon>
<button
  [ngStyle]="{ top: iconTop }"
  class="searchbar__clear"
  (click)="clear()"
  *ngIf="!!searchValue.value || showClose"
>
  <fa-duotone-icon
    secondaryOpacity="0.67"
    class="searchbar__clear__icon"
    icon="xmark"
  ></fa-duotone-icon>
</button>
