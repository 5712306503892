import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'nca-content-box',
  templateUrl: './content-box.component.html',
  styleUrls: ['./content-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBoxComponent {
  @Input() hasIcon = true;
  @Input() title?: string;
  @Input() subtitle?: string;
}

@NgModule({
  declarations: [ContentBoxComponent],
  exports: [ContentBoxComponent],
  imports: [CommonModule],
})
export class ContentBoxModule {}
