import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgArrayPipesModule } from 'ngx-pipes';

@Component({
  selector: 'nca-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectInputComponent
  implements ControlValueAccessor, AfterViewInit
{
  public valueControl = new FormControl();
  @Input() bindValue = 'key';
  @Input() bindLabel = 'value';
  @Input() placeholder?: string;
  @Input() notFoundText?: string;
  @Input() itemWordPlural = 'éléments';
  @Input() itemWordSingular = 'élément';
  @Input() multiple = false;
  @Input() clearable = false;
  @Input() items: any[] = [];

  ngAfterViewInit() {
    this.valueControl.valueChanges.subscribe((x) => {
      this.onChangeFn(x);
    });
  }

  writeValue(value: number): void {
    this.valueControl.setValue(value);
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChangeFn = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChangeFn(_: any): any {}

  clear() {
    this.valueControl.setValue('');
  }

  get placeholderText(): string {
    return this.placeholder ?? `Filtrer par ${this.itemWordSingular}`;
  }
}

@NgModule({
  declarations: [SelectInputComponent],
  exports: [SelectInputComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgArrayPipesModule,
  ],
})
export class SelectInputModule {}
