import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { AuthenticationState } from '@stores/authentication/authentication.state';
import { FeaturesRoutingEnum } from '@features/features.routing';
import { SignRoutingEnum } from '@features/sign/sign.routing';

export const RETURN_URL_KEY = 'return_url';

@Injectable()
export class IsLoggedGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.store.select(AuthenticationState.isLogged).pipe(
      map((isLogged) => {
        if (isLogged) {
          return true;
        }
        localStorage.setItem(RETURN_URL_KEY, state.url);
        return this.router
          .navigate(['/', FeaturesRoutingEnum.Sign, SignRoutingEnum.Login])
          .then(() => false);
      })
    );
  }
}
