import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { getUserName, User } from '@mychrono/models';

@Pipe({
  name: 'userName',
})
export class UserNamePipe implements PipeTransform {
  transform(userObj: User): string {
    return userObj.fullName ? userObj.fullName : getUserName(userObj);
  }
}

@NgModule({
  declarations: [UserNamePipe],
  exports: [UserNamePipe],
})
export class UserNamePipeModule {}
