<div class="table-responsive">
  <table
    class="table table-row-dashed table-row-gray-300 g-2 gx-3 g-sm-3 fade-in"
  >
    <thead>
      <tr class="table__row">
        <th
          *ngFor="let column of config.columns; trackBy: trackByColumn"
          (click)="column.sortable === true && changeSort(column)"
          class="table__row__head"
          [class.table__row__head--sortable]="column.sortable === true"
          [class.table-sort-asc]="
            column.sortable === true &&
            listParams.sortField === column.property &&
            listParams.sortOrder === 1
          "
          [class.table-sort-desc]="
            column.sortable === true &&
            listParams.sortField === column.property &&
            listParams.sortOrder === -1
          "
        >
          {{ column.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <a
        class="table__row"
        [class.table__row--hoverable]="!!config.redirection || rowsClickable"
        *ngFor="let row of data; trackBy: trackByResource"
        [routerLink]="row | pageRedirect : config"
        (click)="rowsClickable && rowClicked.emit(row)"
        queryParamsHandling="preserve"
      >
        <td
          class="table__row__cell"
          [ngStyle]="{
            width: column.width ? column.width + 'px' : '',
            maxWidth: column.maxWidth ? column.maxWidth + 'px' : ''
          }"
          *ngFor="let column of config.columns; trackBy: trackByColumn"
        >
          <nca-cell-view
            [params]="listParams"
            [config]="column"
            [rowData]="row"
          ></nca-cell-view>
        </td>
      </a>
    </tbody>
  </table>
  <nca-empty-list
    *ngIf="loading === false && data.length === 0"
    [itemWordSingular]="itemWordSingular"
    [hasParams]="!!listParams"
  ></nca-empty-list>
</div>
